import { useQueries } from '@tanstack/react-query';
import { getDataQuery } from 'api';
import { getSessionStorage } from 'assets/constant';
import { jwtDecode } from 'jwt-decode';
import { useRecoilValue } from 'recoil';
import { useCalendarMonthData } from 'recoil/caleandar/calendarMonthData';

export function useGetCalendarApi() {
  const calendarMonth = useRecoilValue(useCalendarMonthData);
  const token = getSessionStorage();
  const decoded = jwtDecode(token);

  const calendarApi = useQueries({
    queries: [
      // 캘린더 학사일정 api
      {
        queryKey: ['calendarAcademicData', decoded.sub, calendarMonth.start_at, calendarMonth.end_at],
        queryFn: () =>
          getDataQuery(
            `/activity/account-calendar?account_id=1&start_at=${calendarMonth.start_at}&end_at=${calendarMonth.end_at}`
          ),
      },
      // 캘린더 개인일정 api
      {
        queryKey: ['calendarUserData', decoded.sub, calendarMonth.start_at, calendarMonth.end_at],
        queryFn: () =>
          getDataQuery(`/activity/users/me/calendar?start_at=${calendarMonth.start_at}&end_at=${calendarMonth.end_at}`),
      },
    ],
  });
  const [calendarAcademic, calendarUser] = calendarApi.map((query) => query);
  const [calendarAcademicData, calendarUserData] = calendarApi.map((query) => query?.data?.data);

  const { isLoading: calendarAcademicIsLoading, isError: calendarAcademicIsError } = calendarAcademic;
  const { isLoading: calendarUserIsLoading, isError: calendarUserIsError } = calendarUser;

  return {
    // 학사 일정
    calendarAcademicData,
    calendarAcademicIsLoading,
    calendarAcademicIsError,

    // 유저 일정
    calendarUserData,
    calendarUserIsLoading,
    calendarUserIsError,
  };
}
