import { getLectureQuery } from 'api/getLectureQuery';
import { decimalPointRemovalFn } from 'assets/constant';
import useSsoRouting from 'hooks/common/useSsoRouting';
import { ProgressBar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { loginUser } from 'recoil/loginUser';

export const StudySubject = ({ item }) => {
  const user = useRecoilValue(loginUser);
  const { ssoRouting } = useSsoRouting();
  if (!item) return;

  const handleGoingLecture = async (url) => {
    ssoRouting(url);
    try {
      const getLectureApi = await getLectureQuery('');
      if (getLectureApi?.errorCode) return;
      window.location.replace(getLectureApi?.data);
    } catch (error) {
      console.error('error', error);
    }
  };

  return (
    <tr className='border-bottom' key={item.course_id}>
      <th className='py-3 text-start min-w-80' scope='row'>
        <Link onClick={() => handleGoingLecture(item.course_url)} className='fz-16'>
          {item?.course_name}
        </Link>
      </th>
      <td className='py-3 d-none d-sm-table-cell'>진도율 {decimalPointRemovalFn(item.progress_rate)}%</td>
      <td className='w-50 pe-3'>
        <ProgressBar striped variant='chaInit' now={decimalPointRemovalFn(item.progress_rate)} animated />
      </td>
      <td className='py-3'>
        <Link onClick={() => handleGoingLecture(item.course_url)}>
          <span className='badge link-badge border fz-14'>과목 홈 가기</span>
        </Link>
      </td>
    </tr>
  );
};
