/**
 *
 * @param {string} title 제목 타이틀
 * @param {element} children NodeElement
 * @returns
 */
export function CardHeader({ title, children, noRightBorder = false, visibleContent = true, margin = true }) {
  return (
    <div className={`d-flex ${margin && 'mb-20'} align-items-center position-relative`}>
      <h3 className={`${noRightBorder && 'after-rightBorder'} me-3 fz-20 fw-bold letter-6 white-nowrap`}>{title}</h3>
      {visibleContent && <div className='text-gray2 letter-6 w-100 fz-18'>{children && children}</div>}
    </div>
  );
}
