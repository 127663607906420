/**
 * 카테고리 타입을 한글로 변환하는 유틸 함수입니다.
 *
 * @param {string} string - 변환할 카테고리 타입 문자열
 * @returns {string} - 한글로 변환된 카테고리 타입 문자열
 */
export const categoryType = (string) => {
  let type = '';

  switch (string) {
    case 'Assignment':
      type = '과제';
      break;
    case 'Quiz':
      type = '평가';
      break;
    case 'Discussion':
      type = '토론';
      break;
    case 'ExternalTool':
      type = '온라인';
      break;
    case 'zoom':
      type = 'zoom';
      break;
    case '동영상':
      type = '동영상';
      break;
    case 'File':
      type = '파일';
      break;
    case 'ExternalUrl':
      type = '링크';
      break;
    default:
      type = '강의';
      break;
  }
  return type;
};

/**
 * 주어진 문자열이 'zoom', 'File', 'SubHeader', 'ExternalUrl' 중 하나인지 확인합니다.
 * @param {string} string 확인할 문자열
 * @returns {boolean} 주어진 문자열이 'zoom', 'File', 'SubHeader', 'ExternalUrl' 중 하나인 경우 true를 반환하고, 그렇지 않은 경우 false를 반환합니다.
 */
export const viewTypeControl = (string) => {
  return string === 'zoom' || string === 'File' || string === 'SubHeader' || string === 'ExternalUrl' ? true : false;
};

/**
 * 주어진 문자열이 'zoom', 'File', '동영상', 'SubHeader' 중 하나인지 확인합니다.
 * @param {string} string 확인할 문자열
 * @returns {boolean} 주어진 문자열이 'zoom', 'File', '동영상', 'SubHeader' 중 하나인 경우 true를 반환하고, 그렇지 않은 경우 false를 반환합니다.
 */
export const viewMoreTypeControl = (string) => {
  return string === 'zoom' || string === 'File' || string === 'SubHeader' ? true : false;
};

export const viewSubjectTypeControl = (string) => {
  return string === 'Assignment' || string === 'Quiz' || string === '동영상' ? false : true;
};

export const percentViewer = (number) => {
  const checkNumber = typeof number;

  if (checkNumber) {
    return number;
  }
  return 0;
};

export const checkStatusValue = (type, item) => {
  if (!viewMoreTypeControl(type)) {
    if (item.type === '동영상') return `${item.percent} %`;
    if (item.type === 'Assignment' || item.type === 'Quiz') {
      return `${item.score === '미채점' || !item.score ? '-' : item.score} / ${item.possiblePoint === '미채점' || !item.possiblePoint ? '-' : item.possiblePoint}`;
    }
  }
};
