import { useQueryClient } from '@tanstack/react-query';
import { getSessionStorage, removeSessionStorage } from 'assets/constant';
import axios from 'axios';
import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router';
import './style.css';

// 1. 로그인 안된 유저는 접근불가
// 2. 로그아웃후 페이지 전환
export function Logout() {
  const [cookies, setCookies, removeCookies] = useCookies(['token']);
  const queryClient = useQueryClient();
  const removeQueryFn = () => {
    queryClient.removeQueries();
  };
  const navigate = useNavigate();

  // TODO 로그아웃 커스텀훅 만들기
  const userLogout = async () => {
    const access = getSessionStorage();
    const refresh = cookies.refresh;

    try {
      const res = await axios.patch(
        `${process.env.REACT_APP_SERVER}/logout`,
        {},
        {
          headers: {
            Authorization: `Bearer ${access}`,
            Refresh: refresh,
          },
        }
      );

      removeSessionStorage();
      removeCookies('refreshToken');
      // 로그아웃 완료
      navigate('/login', { replace: true });
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    userLogout();
  }, []);
  // const
  return (
    <>
      <div className='loader'>
        <div className='item-1'>
          <span></span>
        </div>
        <div className='item-2'>
          <span></span>
        </div>
        <div className='item-3'>
          <span></span>
        </div>
        <div className='item-4'>
          <span></span>
        </div>
        <div className='item-5'>
          <span></span>
        </div>
        <div className='item-6'>
          <span></span>
        </div>
        <div className='item-7'>
          <span></span>
        </div>
        <div className='item-8'>
          <span></span>
        </div>
      </div>
    </>
  );
}
