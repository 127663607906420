import { CardHeader } from 'components/common';
import { useHeader } from 'hooks';
import { DashboardAdminItem } from '..';
import { DashboardPopnoto } from './DashboardPopnoto';

export default function DashboardTopCards() {
  const {
    // 메세지
    messageData,
    messageIsLoading,
    messageIsError,
  } = useHeader();
  return (
    <div className='row'>
      {/* 과제 */}
      <div className='col-6'>
        <div className='card info-card card-min sales-card p-3'>
          <div className=''>
            <CardHeader title='신규문의'>미확인</CardHeader>

            <div className='d-flex align-items-center'>
              <div className='card-icon rounded-circle d-flex align-items-center justify-content-center CHA_LIGHT'>
                <i className='bi bi-envelope-exclamation-fill color-primary'></i>
              </div>
              <div className='me-2 w-100 text-end d-flex justify-content-end align-items-center'>
                <h6 className='ms-3'>
                  <span className='me-3 --Palette-DarkGray-80'>{messageData?.length ? messageData?.length : 0}</span>개
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>

      <DashboardAdminItem index={1} link={`${process.env.REACT_APP_LECTURE}`}>
        온라인 강의실
      </DashboardAdminItem>

      <DashboardPopnoto index={2} link={`${process.env.REACT_APP_PANOPTO}`}>
        CMS (VOD)
      </DashboardPopnoto>

      {/* 학습과목 */}
      {/* <StudentProgress /> */}
      {/* <LeadingSubject data={subjectsStatesData} isLoading={subjectsStatesisIoading} /> */}

      {/* 과목 공지사항 */}
      {/* <SubjectNotice /> */}
    </div>
  );
}
