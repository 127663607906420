import { useRecoilValue } from 'recoil';
import { subjectValueState } from 'recoil/subject/subjectValueState';

/**
 * 학생 과목 제목 카드 컴포넌트입니다.
 *
 * @returns {JSX.Element} 학생 과목 제목 카드 컴포넌트
 */
export default function SubjectTitleCard({ viewVideo = false }) {
  const subjectState = useRecoilValue(subjectValueState);
  return (
    <section className='card p-20 py-4 d-flex justify-content-between align-items-center flex-row'>
      <div className='d-flex fw-bold fz-18'>
        <h3 className='d-flex --Palette-DarkGray-80'>{subjectState.course_name}</h3>
      </div>
      <div className='d-flex fz-14 noto-sans align-items-center'>
        <p className='ms-3 fz-12 d-flex align-items-center p-2 rounded text-blue-primary CHA_LIGHT'>
          <span className=''>교과코드:</span>
          <span className='fw-bold'>{subjectState.course_code && subjectState.course_code}</span>
        </p>
        {viewVideo && (
          <p className='ms-3 fz-12 d-flex align-items-center p-2 rounded text-blue-primary bg-cardZoomOption'>
            <span className=''>동영상 출석인정기준: 95 %</span>
          </p>
        )}
      </div>
    </section>
  );
}
