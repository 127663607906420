import { useQuery } from '@tanstack/react-query';
import { getDataQuery } from 'api';
import { arrChunk } from 'assets/constant';
import { CardHeader } from 'components/common';
import { PaginationCustom } from 'components/common/pagination';
import { useGetQuery } from 'hooks';
import { useCallback, useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useSubjectNotice } from 'recoil/dashboard/subjectNotice';
import { subjectNoticePopupState } from 'recoil/dashboard/subjectNoticePopupState';
import { useUserState } from 'recoil/useUserState';

export const SubjectNotice = ({ data, cardTitle, isLoading }) => {
  const [optionData, setOptionData] = useState([]);
  const [search, setSearch] = useState('');
  const [noticeList, setNoticeList] = useRecoilState(useSubjectNotice);
  const setSubjectNoticePopup = useSetRecoilState(subjectNoticePopupState);
  const [noticeCourseId, setNoticeCourseId] = useState({ id: null, subjectTitle: '' });
  const [currentPage, setCurrentPage] = useState(1);
  const user = useRecoilValue(useUserState);

  // 전체 과목안에 있는 전체 공지사항 불러오기
  const { studentAllNoticeListData, studentAllNoticeListIsLoading, studentAllNoticeListIsError } = useGetQuery();

  const { data: homeSubjectNoticeData } = useQuery({
    queryKey: ['getNoticeList', noticeCourseId, user.sub],
    queryFn: async () => {
      try {
        const res = await getDataQuery(`/home/users/me/enrollment/${noticeCourseId.id}/notice/all`);
        return res;
      } catch (error) {
        console.error('error', error);
      }
    },
    enabled: !!noticeCourseId.id,
  });

  const handleSelectCourseId = useCallback(
    (e) => {
      const findData = data?.find((el) => +el.course_id === +e.value);
      setNoticeCourseId({
        id: e.value,
        subjectTitle: findData.course_name,
      });
      setCurrentPage(1);
    },
    [data]
  );

  const handleSortIndexBtn = useCallback(
    (type) => {
      if (type === 'up') return setNoticeList([...noticeList].sort((a, b) => a.id - b.id));
      if (type === 'down') return setNoticeList([...noticeList].sort((a, b) => b.id - a.id));
    },
    [noticeList, setNoticeList]
  );

  const handleSearchBar = useCallback(
    (e) => {
      e.preventDefault();
      // 과목이 선택되어있지 않을때
      if (!noticeCourseId.id) {
        const searchSubjectNoticeList = studentAllNoticeListData?.filter((el) => el.title.includes(search));
        setNoticeList(searchSubjectNoticeList);
        setCurrentPage(1);
        return;
      }
      // 과목이 선택되어있을때
      if (noticeCourseId.id) {
        const searchSubjectNoticeList = homeSubjectNoticeData?.data.filter((el) => el.title.includes(search));
        setNoticeList(searchSubjectNoticeList);
        setCurrentPage(1);
        return;
      }
    },
    [homeSubjectNoticeData, search, setNoticeList]
  );

  // 데이터 패칭시 전역으로 상태관리
  useEffect(() => {
    if (!homeSubjectNoticeData) return setNoticeList([]);
    setNoticeList(homeSubjectNoticeData?.data);
  }, [noticeCourseId, homeSubjectNoticeData, setNoticeList]);

  useEffect(() => {
    if (!studentAllNoticeListData) return;
    return setNoticeList(studentAllNoticeListData);
  }, [studentAllNoticeListData, noticeCourseId]);

  // 클리어 이펙트
  useEffect(() => {
    return () => {
      setCurrentPage(1);
      setSearch('');
    };
  }, []);

  useEffect(() => {
    return setOptionData(
      data?.map((item) => ({
        value: item.course_id,
        label: item.course_code,
      }))
    );
  }, [data]);

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      resolve(
        data?.map((item) => ({
          value: item.course_id,
          label: item.course_code,
        }))
      );
    });
  return (
    <div className='col-12'>
      <div className='card p-20'>
        <div className=''>
          <CardHeader title='과목 공지사항'>{cardTitle}</CardHeader>
          <div className=''>
            <div className='d-flex justify-content-between w-100'>
              <div className='d-flex w-100'>
                <label
                  className='me-3 text-nowrap d-flex justify-content-center align-items-center'
                  htmlFor='subjectNotice'
                >
                  과목
                </label>
                {/* <Form.Select
                  className='ms-2 white-nowrap w-100 py-0'
                  id='subjectNotice'
                  name='subjectNotice'
                  onChange={handleSelectCourseId}
                  style={{ height: '31px' }}
                >
                  <option value=''>전체</option>
                  {data?.map((item) => (
                    <option key={item.course_id} value={item.course_id}>
                      {item.name}
                    </option>
                  ))}
                </Form.Select> */}

                <AsyncSelect
                  className='w-100'
                  defaultInputValue='과목 선택'
                  placeholder='과목 선택'
                  isLoading={isLoading}
                  cacheOptions
                  defaultOptions={optionData}
                  onChange={handleSelectCourseId}
                  loadOptions={promiseOptions}
                />
              </div>
            </div>
            <div className='col'>
              {/* <form onSubmit={handleSearchBar} className='search-form d-flex align-items-center'>
                <input
                  type='text'
                  className='search-input w-100'
                  name='query'
                  placeholder='검색'
                  title='Enter search keyword'
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                  style={{ height: '31px' }}
                />
                <button>
                  <i className='bi bi-search'></i>
                </button>
              </form> */}
            </div>
          </div>
          <div className='d-flex justify-content-center mt-2 mb-4'></div>
          <div className='table-responsive'>
            <table className='table'>
              <thead>
                <tr className='border-bottom border-top --Palette-DarkGray-80 fw-bold'>
                  <th style={{ textAlign: 'start', width: '16.66%', padding: '4px' }}>
                    <div className='d-flex align-items-center justify-content-between'>
                      <h4 className='py-3 min-w-70'>번호</h4>
                    </div>
                  </th>
                  <th style={{ textAlign: 'start', width: '16.66%', padding: '4px' }}>
                    <div>
                      <h4 className='py-3'>과목</h4>
                    </div>
                  </th>
                  <th style={{ textAlign: 'start', width: '30.33%', padding: '4px' }}>
                    <div>
                      <h4 className='py-3'>제목</h4>
                    </div>
                  </th>
                  <th style={{ textAlign: 'start', width: '25%', padding: '4px' }}>
                    <div className='d-flex align-items-center justify-content-between'>
                      <h4 className='py-3'>작성일</h4>
                      <div className='d-flex flex-column'>
                        <button
                          onClick={() => handleSortIndexBtn('up')}
                          className='btn btn-style-none btn-style-hover p-0'
                        >
                          <i className='bi bi-caret-up-fill --Palette-Gray-50'></i>
                        </button>
                        <button
                          onClick={() => handleSortIndexBtn('down')}
                          className='btn btn-style-none btn-style-hover p-0'
                        >
                          <i className='bi bi-caret-down-fill --Palette-Gray-50'></i>
                        </button>
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {(homeSubjectNoticeData?.data && homeSubjectNoticeData?.data?.length < 1) ||
                studentAllNoticeListData?.length === 0 ? (
                  <tr>
                    <td colSpan={4} className='py-5 fw-bold' style={{ textAlign: 'center' }}>
                      등록된 과목 공지사항이 없습니다.
                    </td>
                  </tr>
                ) : (
                  arrChunk(noticeList).length > 0 &&
                  arrChunk(noticeList)[currentPage - 1]?.map((item, index) => {
                    const date = item.created_at?.slice(0, 10);
                    return (
                      <tr
                        className='border-bottom'
                        key={item.id}
                        onClick={() => {
                          setSubjectNoticePopup({ visible: true, data: item });
                          // handleGoingNotice(item.url);
                        }}
                      >
                        <th style={{ textAlign: 'start', width: '16.66%', padding: '4px' }}>
                          <div className='p-1'>
                            {currentPage === 1 && index + 1}
                            {currentPage > 1 && (currentPage - 1) * 5 + (index + 1)}
                          </div>
                        </th>
                        <td style={{ textAlign: 'start', width: '16.66%', padding: '4px' }}>
                          {noticeCourseId.id ? (
                            <div className='py-3'>{noticeCourseId.subjectTitle}</div>
                          ) : (
                            <div className='py-3'>{item.course_name}</div>
                          )}
                        </td>
                        <td style={{ textAlign: 'start', width: '30.33%', padding: '4px' }}>
                          {/* TODO 공지사항 봤는지 안봤는지 */}
                          {/* <div className={`py-3 ${item.checked && 'fw-bold'}`}>
                            {item.title}
                            {item.checked && (
                              <span
                                className='px-2 py-1 rounded-5 ms-3'
                                style={{ background: '#3758F910', color: '#3758F9' }}
                              >
                                N
                              </span>
                            )}
                          </div> */}
                          <div className='py-3'>{item.title}</div>
                        </td>

                        <td style={{ textAlign: 'start', width: '25%', padding: '4px' }}>
                          <div className='py-3'>{date}</div>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
            {/* 페이지네이션 */}

            {studentAllNoticeListData?.length > 0 && (
              <div className='d-flex align-items-center justify-content-center position-relative'>
                <p className='d-none d-md-block float-left position-absolute top-left-50 fz-12'>
                  전체 게시물수 : {noticeList.length} 전체 페이지 :
                  {homeSubjectNoticeData?.data && homeSubjectNoticeData?.data.length < 1 ? 0 : currentPage}
                  &nbsp; / {Math.ceil(noticeList.length / 5)}
                </p>
                <PaginationCustom currentPage={currentPage} setCurrentPage={setCurrentPage} data={noticeList} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
