/**
 *
 * @param {boolean} completed 제출 완료 불린 값
 * @param {string} completedAt 제출한 시간
 * @param {string} dueAt 마감 기간
 * @returns
 */
export function IsVideoSuccess({ state, importantState }) {
  let type = '';

  switch (state) {
    case '출석':
      type = 'green';
      break;
    case '결석':
      type = 'red';
      break;
    case '지각':
      type = 'orange';
      break;
    case '연동중':
    case '미반영':
      type = 'gray';
      break;

    default:
      break;
  }

  if (importantState) type = 'green';

  return (
    <p
      className={`fw-bold noto-sans fz-12 py-1 px-3 rounded-5 text-nowrap lh-22
      ${type === 'red' && 'isSuccess-red'} 
      ${type === 'orange' && 'isSuccess-orange'} 
      ${type === 'green' && 'isSuccess-green'} 
      ${type === 'gray' && 'isSuccess-gray'} 
      `}
    >
      {/* {state} */}
      {type === 'green' && '출석'}
      {type === 'orange' && '지각'}
      {type === 'red' && '결석'}
      {type === 'gray' && '미연동'}
    </p>
  );
}
