import { useMutation, useQueryClient } from '@tanstack/react-query';
import { postDataQuery } from 'api/board/postQuery';
import { getSessionStorage } from 'assets/constant';
import { jwtDecode } from 'jwt-decode';

export function usePostSupporting(path) {
  const token = getSessionStorage();
  const decoded = jwtDecode(token);

  const queryClient = useQueryClient();

  // 공지사항 글작성 mutation
  const writeMutation = useMutation({
    mutationFn: (formData) => postDataQuery('/help/notification', formData),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['notice&news', decoded] });
    },
    onError: (error) => {
      console.error('error', error);
    },
  });

  // faq 글작성 mutation
  const writeFaqMutation = useMutation({
    mutationFn: (formData) => postDataQuery('/help/faq', formData),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['FAQ', decoded] });
    },
    onError: (error) => {
      console.error('error', error);
    },
  });

  // 자료실 글작성 mutation
  const writeReferenceMutation = useMutation({
    mutationFn: (formData) => postDataQuery('/help/data-room', formData),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['reference', decoded] });
    },
    onError: (error) => {
      console.error('error', error);
    },
  });

  // 공지사항 수정
  const editFileMutate = useMutation({
    mutationFn: (data) => {
      postDataQuery(`/attach?post-id=${path}`, data);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['referenceDetail', decoded, path] });
      queryClient.invalidateQueries({ queryKey: ['notice&newsDetail', decoded, path] });
      queryClient.invalidateQueries({ queryKey: ['FAQDetail', decoded, path] });
    },
    onError: (error) => {
      console.error('error', error);
    },
  });

  return {
    // notice
    writeMutation,
    editFileMutate,
    writeReferenceMutation,

    // faq
    writeFaqMutation,
  };
}
