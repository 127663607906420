import { useNavigate } from 'react-router';

export function NotFound() {
  const navigate = useNavigate();
  const handleGoingLogin = () => {
    navigate('/');
  };
  return (
    <div className='not-found'>
      <div>
        <h1>
          5
          <div className='not-found-circle d-flex align-items-center justify-content-center'>
            <img src='/assets/img/notFound.svg' alt='나침반모양 이미지' />
          </div>
          0
        </h1>
        <p className='recheck-found'>
          {/* 다시 한번 확인해주세요! */}
          해당 현상에 대해 확인중입니다.
        </p>{' '}
        <p className='description-found fz-18'>
          {/* 지금 입력하신 주소의 페이지는 사라졌거나 다른 페이지로 변경되었습니다.
          <br />
          주소를 다시 확인해주세요 */}
          {/* 해당 현상에 대해 확인중입니다. */}
        </p>
        <p className='description-found'></p>
        <button onClick={handleGoingLogin}>메인 페이지</button>
      </div>
    </div>
  );
}
