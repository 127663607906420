export default function FaqCard({ children, title, icon = 'bi-telephone' }) {
  return (
    <div className='col-12 col-sm-6 col-lg-3'>
      <div className='p-3 card card-min-170'>
        <div>
          <i className={`bi ${icon} color-second`} style={{ fontSize: '38px' }}></i>
        </div>
        <div>
          <h4 className='h2 fw-bold px-1 py-3'>{title}</h4>
          {children}
        </div>
      </div>
    </div>
  );
}
