import { useQueries } from '@tanstack/react-query';
import { getDataQuery } from 'api';
import { getSessionStorage } from 'assets/constant';
import { jwtDecode } from 'jwt-decode';
export const useGetQuery = () => {
  const token = getSessionStorage();
  const decoded = jwtDecode(token);
  const checkStudent = decoded.role === 'STUDENT';

  const studentHomeApis = useQueries({
    queries: [
      // 학생 홈/ 과제, 퀴즈 미제출
      {
        queryKey: ['StudentAssignment', decoded.sub],
        queryFn: () => getDataQuery('/home/users/me/assignment/all'),
        enabled: checkStudent,
      },
      // 학생 홈/ 학습과목
      {
        queryKey: ['StudentSubjectsStates', decoded.sub],
        queryFn: () => getDataQuery('/home/users/me/subjects-states'),
        enabled: checkStudent,
      },
      // 학생 - [홈] 과목 공지사항/공지사항 리스트
      {
        queryKey: ['StudentEnrollment', decoded.sub],
        queryFn: () => getDataQuery('/home/users/me/enrollment/all'),
        enabled: checkStudent,
      },
      // 공통 - [홈] 대쉬보드 활동 스트림
      {
        queryKey: ['StudentActivityStream', decoded.sub],
        queryFn: () => getDataQuery('/activity/users/me'),
      },
      // 공통 - [홈] - 전체 과목 공지사항
      {
        queryKey: ['StudentAllNoticeList', decoded.sub],
        queryFn: () => getDataQuery('/home/users/me/notice/all'),
      },
    ],
  });

  const [assignment, subjectsStates, dashboradLectureNotice, activitySteam, studentAllNoticeList] = studentHomeApis.map(
    (query) => query
  );

  const [assignmentData, subjectsStatesData, dashboradLectureNoticeData, activitySteamData, studentAllNoticeListData] =
    studentHomeApis.map((query) => query?.data?.data);

  const { isLoading: assignmentIsLoading, isError: assignmentIsError } = assignment;
  const { isLoading: subjectsStatesIsLoading, isError: subjectsStatesIsError } = subjectsStates;
  const { isLoading: dashboradLectureNoticeIsLoading, isError: dashboradLectureNoticeIsError } = dashboradLectureNotice;
  const { isLoading: activitySteamIsLoading, isError: activitySteamIsError } = activitySteam;
  const { isLoading: studentAllNoticeListIsLoading, isError: studentAllNoticeListIsError } = studentAllNoticeList;

  return {
    // 학생 홈/ 과제, 퀴즈 미제출
    assignmentData,
    subjectsStatesData,

    // 학생 홈/ 학습과목
    assignmentIsLoading,
    subjectsStatesIsLoading,

    // [홈] 과목공지사항 (대쉬보드 공지사항 불러오기)
    assignmentIsError,
    subjectsStatesIsError,

    // 학생 - [홈] 과목 공지사항/공지사항 리스트
    dashboradLectureNoticeData,
    dashboradLectureNoticeIsLoading,
    dashboradLectureNoticeIsError,

    // 학생 - [홈] 활동 스트림
    activitySteamData,
    activitySteamIsLoading,
    activitySteamIsError,

    // 학생 - [홈] 공지사항 전체 불러오기
    studentAllNoticeListData,
    studentAllNoticeListIsLoading,
    studentAllNoticeListIsError,
  };
};
