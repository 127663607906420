/**
 * HTML 태그를 제거하는 함수
 *
 * @param {string} tag - 태그를 포함할 수 있는 문자열
 * @returns {string} 태그가 제거된 문자열을 반환
 */
export const removeTag = (tag) => {
  const doc = new DOMParser().parseFromString(tag, 'text/html');
  return doc.body.textContent.replace(/\s+/g, ' ').trim();
};
