import { Link } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { dropMenuSupportState } from 'recoil/dropmenu/dropMenuSupportState';

/**
 *
 * @param {string} title 드롭메뉴 타이틀
 * @param {number} tapIndex 드롭메뉴의 인덱스
 * @param {string} link 드롭메뉴의 인덱스
 * @returns
 */
export function DropMenuItem({ title, tapIndex, link, active }) {
  const [dropMenuIndex, setDropMenuIndex] = useRecoilState(dropMenuSupportState);
  const handleOnclickIndex = () => {
    setDropMenuIndex(tapIndex);
  };

  return (
    <li
      onClick={handleOnclickIndex}
      className={`rounded cursor-pointer dropMenu-item ${dropMenuIndex === tapIndex && 'sidebar-active'}`}
    >
      <Link to={link} className='color-inherit w-100 py-3'>
        <div className='d-flex align-items-center justify-content-start dropmenu-item--before'>
          <div className=''>
            <div className='d-flex align-items-center rounded fz-16 font-suit'>
              <span className={`${dropMenuIndex === tapIndex && 'text-white'}`}>{title}</span>
            </div>
          </div>
        </div>
      </Link>
    </li>
  );
}
