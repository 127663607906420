import React from 'react';
import { Link } from 'react-router-dom';

/**
 *
 * @param {string} home 상위 태그의 링크 주소
 * @param {string} title  상위 태그의 컨텐츠 내용
 * @param {string} sublink  하위 경로의 링크 주소
 * @param {string} subTitle  하위 경로의 컨텐츠 내용
 * @returns
 */
const BreadCrumb = (props) => {
  return (
    <div className='pagetitle'>
      <h1>
        <Link className='h1' to={props.home}>
          {props.title}
        </Link>
      </h1>
      <nav className='mt-1'>
        <ol className='breadcrumb fw-normal color-second'>
          <li
            className='breadcrumb-item active fw-normal border-bottom border-primary color-primary'
            style={{ paddingBottom: '1px' }}
          >
            {/* <Link to={props.sublink}>{props.title}</Link> */}
            <Link className='color-primary' to={props.sublink}>
              {props.subTitle}
            </Link>
          </li>
          {props.children}
        </ol>
      </nav>
    </div>
  );
};

export default React.memo(BreadCrumb);
