import { activeDateSliceFn } from 'assets/constant';

export function ActivitiesTitleDate({ data }) {
  return (
    <p>
      <span className='me-1'>{activeDateSliceFn(data)[0]}년도</span>
      <span className='me-1'>{activeDateSliceFn(data)[1]}월</span>
      <span className='me-1'>{activeDateSliceFn(data)[2]}일</span>
    </p>
  );
}
