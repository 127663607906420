import { NOTICE } from 'assets/constant';
import 'assets/css/editorCoston.css';
import BreadCrumb from 'components/shared/BreadCrumb';
import { Sidebar } from 'components/shared/sidebar';
import { useSupporting } from 'hooks';
import { useNoticeState } from 'hooks/supporting/useNoticeState';
import { usePatchSupporting } from 'hooks/supporting/usePatchSupporting';
import moment from 'moment';
import { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router';
import { useRecoilValue } from 'recoil';
import { loginUser } from 'recoil/loginUser';
import { handleDownloadFile } from 'util/supporting/noticeUtil';

export const NoticeDetail = () => {
  const navigate = useNavigate();
  const { noticeId } = useParams();
  const userInfo = useRecoilValue(loginUser);
  const { noticeDeleteMutation } = usePatchSupporting();

  const {
    noticeState,
    handleTitleChange,
    handleContentChange,
    handlePostStateChange,
    handlePostTypeChange,
    handleTopFixFlagChange,
    // handleThumbnailChange,
    handleFileChange,
    resetNoticeState,
    handleEditFileChange,
  } = useNoticeState();

  const {
    // 지원센터 공지&뉴스 디테일 api
    supportNoticeNewsDetailData,
    supportNoticeNewsDetailIsLoading,
    supportNoticeNewsDetailIsError,
  } = useSupporting(noticeId, NOTICE);

  const handleDeleteNotice = () => {
    const checkDelete = window.confirm('정말 삭제하시겠습니까?');
    if (!checkDelete) return;

    try {
      noticeDeleteMutation.mutate(noticeId);
      navigate('/notice');
    } catch (error) {
      console.error('error', error);
    }
  };
  const handleGoingPage = (type) => {
    navigate(`/notice/edit/${noticeId}`);
  };

  const handleGoingList = (type) => {
    navigate(`/notice`);
  };

  const handleDownLoad = (item) => {
    handleDownloadFile(item);
  };

  useEffect(() => {
    // localStorage.setItem('noticeData', JSON.stringify(supportNoticeNewsDetailData));
    handleTitleChange(supportNoticeNewsDetailData?.title);
    handleContentChange(supportNoticeNewsDetailData?.content);
    handlePostStateChange(supportNoticeNewsDetailData?.postState);
    handlePostTypeChange(supportNoticeNewsDetailData?.postType);
    handleTopFixFlagChange(supportNoticeNewsDetailData?.topFixFlag);
    handleEditFileChange(supportNoticeNewsDetailData?.mediaInfoDtoList);
  }, [supportNoticeNewsDetailData]);

  return (
    <>
      <Sidebar />
      <main id='main' className='main'>
        <BreadCrumb home={'/notice'} sublink={'/notice'} title={'Help Center'} subTitle={'지원센터'}>
          &nbsp;/ 공지사항 / {supportNoticeNewsDetailData?.title}
        </BreadCrumb>
        <section className='card p-20'>
          <div className='fw-bold d-flex justify-content-between align-items-center mt-2 mb-4'>
            <h3>{supportNoticeNewsDetailData?.title}</h3>
            <div className='d-flex'>
              {userInfo?.role === 'ADMIN' && (
                <>
                  <Button
                    onClick={() => handleGoingPage('write')}
                    className='ms-2'
                    style={{ background: '#0D6EFD' }}
                    variant='secondary'
                  >
                    <i className='bi bi-pencil-fill'></i> 수정
                  </Button>
                  <Button
                    onClick={handleDeleteNotice}
                    className='ms-2'
                    style={{ background: '#6C757D' }}
                    variant='secondary'
                  >
                    <i className='bi bi-trash'></i> 삭제
                  </Button>
                </>
              )}
              <Button onClick={handleGoingList} className='ms-2' style={{ background: '#13426B' }} variant='secondary'>
                <i className='bi bi-list'></i> 목록
              </Button>
            </div>
          </div>
          <div className='d-flex justify-content-between bg-gray10 p-3 border border-start-0 border-end-0'>
            <p className='d-flex flex-column flex-sm-row'>
              <span className='pe-2'>작성자</span>
              <span className='fw-bold text-gray1'>{supportNoticeNewsDetailData?.writer}</span>
            </p>

            <div className='d-flex justify-content-end'>
              <p className='d-flex flex-column flex-sm-row me-3'>
                <span className='pe-2'>등록일</span>
                <span>
                  {supportNoticeNewsDetailData?.createdDate
                    ? moment(supportNoticeNewsDetailData?.createdDate).format('YYYY.MM.DD')
                    : '-'}
                </span>
              </p>
              <p className='d-flex flex-column flex-sm-row'>
                <span className='pe-2'>조회수</span>
                <span>{supportNoticeNewsDetailData?.readCount}</span>
              </p>
            </div>
          </div>
          <div>
            <div
              className='mt-4 mb-2 editor_custom--style'
              dangerouslySetInnerHTML={{ __html: supportNoticeNewsDetailData?.content }}
            ></div>
          </div>
          {supportNoticeNewsDetailData?.mediaInfoDtoList?.length > 0 && (
            <div className='border mx-2 rounded-1 p-2 mb-5 cursor-pointer mt-4'>
              <ul>
                {supportNoticeNewsDetailData?.mediaInfoDtoList?.map((item) => (
                  <li key={item.fileId} className='py-2'>
                    <button
                      onClick={() => {
                        handleDownLoad(item);
                      }}
                      className='d-flex align-items-center justify-content-between bg-none border-none w-100'
                    >
                      <span className='text-blue1'>{item.originalName}</span>
                      <i className='bi bi-download'></i>
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </section>
      </main>
    </>
  );
};
