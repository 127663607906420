import { atom, selector } from 'recoil';

export const noticeWriteState = atom({
  key: 'noticeWriteState',
  default: {
    title: '',
    content: '',
    postState: 'OPEN',
    postType: 'NOTIFICATION',
    topFixFlag: 'N',
    thumbnail: null,
    fileList: [],
    editFileList: [],
  },
});

export const setNoticeTitleState = selector({
  key: 'setNoticeTitleState',
  get: ({ get }) => {
    const noticeWrite = get(noticeWriteState);
    return noticeWrite.title;
  },
  set: ({ set }, newTitle) => {
    set(noticeWriteState, (prevNoticeWrite) => ({
      ...prevNoticeWrite,
      title: newTitle,
    }));
  },
});

export const setNoticeContentState = selector({
  key: 'setNoticeContentState',
  get: ({ get }) => {
    const noticeWrite = get(noticeWriteState);
    return noticeWrite.content;
  },
  set: ({ set }, newContent) => {
    set(noticeWriteState, (prevNoticeWrite) => ({
      ...prevNoticeWrite,
      content: newContent,
    }));
  },
});

export const setNoticePostStateState = selector({
  key: 'setNoticePostStateState',
  get: ({ get }) => {
    const noticeWrite = get(noticeWriteState);
    return noticeWrite.postState;
  },
  set: ({ set }, newPostState) => {
    set(noticeWriteState, (prevNoticeWrite) => ({
      ...prevNoticeWrite,
      postState: newPostState,
    }));
  },
});

export const setNoticePostTypeState = selector({
  key: 'setNoticePostTypeState',
  get: ({ get }) => {
    const noticeWrite = get(noticeWriteState);
    return noticeWrite.postType;
  },
  set: ({ set }, newPostType) => {
    set(noticeWriteState, (prevNoticeWrite) => ({
      ...prevNoticeWrite,
      postType: newPostType,
    }));
  },
});

export const setNoticeTopFixFlagState = selector({
  key: 'setNoticeTopFixFlagState',
  get: ({ get }) => {
    const noticeWrite = get(noticeWriteState);
    return noticeWrite.topFixFlag;
  },
  set: ({ set }, newTopFixFlag) => {
    set(noticeWriteState, (prevNoticeWrite) => ({
      ...prevNoticeWrite,
      topFixFlag: newTopFixFlag,
    }));
  },
});

export const setNoticeFileListState = selector({
  key: 'setNoticeFileListState',
  get: ({ get }) => {
    const noticeWrite = get(noticeWriteState);
    return noticeWrite.fileList;
  },
  set: ({ set }, newFileList) => {
    set(noticeWriteState, (prevNoticeWrite) => ({
      ...prevNoticeWrite,
      fileList: newFileList,
    }));
  },
});

export const setNoticeEditFileListState = selector({
  key: 'setNoticeEditFileListState',
  get: ({ get }) => {
    const noticeWrite = get(noticeWriteState);
    return noticeWrite.fileEditList;
  },
  set: ({ set }, newFileList) => {
    set(noticeWriteState, (prevNoticeWrite) => ({
      ...prevNoticeWrite,
      editFileList: newFileList,
    }));
  },
});

export const setNoticeThumbnailListState = selector({
  key: 'setNoticeThumbnailListState',
  get: ({ get }) => {
    const noticeWrite = get(noticeWriteState);
    return noticeWrite.thumbnail;
  },
  set: ({ set }, thumbnail) => {
    set(noticeWriteState, (prevNoticeWrite) => ({
      ...prevNoticeWrite,
      thumbnail: thumbnail,
    }));
  },
});
