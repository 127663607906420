import { useQueries } from '@tanstack/react-query';
import { getDataQuery } from 'api';
import { getSessionStorage } from 'assets/constant';
import { jwtDecode } from 'jwt-decode';

export function useHeader() {
  //
  const token = getSessionStorage();
  const decoded = jwtDecode(token);
  const headerApi = useQueries({
    queries: [
      // 헤더 메세지 api
      {
        queryKey: ['message', decoded.sub],
        queryFn: () => getDataQuery(`/message`),
      },
      // 헤더 알람 api
      {
        queryKey: ['notice', decoded.sub],
        queryFn: () => getDataQuery(`/notice`),
      },
    ],
  });

  const [message, notice] = headerApi.map((query) => query);
  const [messageData, noticeData] = headerApi.map((query) => query?.data?.data);

  const { isLoading: messageIsLoading, isError: messageIsError } = message;
  const { isLoading: noticeIsLoading, isError: noticeIsError } = notice;

  return {
    // 메세지
    messageData,
    messageIsLoading,
    messageIsError,
    // 알람
    noticeData,
    noticeIsLoading,
    noticeIsError,
  };
}
