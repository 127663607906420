import { cookieDomainDdu } from 'assets/constant';
import { useRecoilValue } from 'recoil';
import { useUserState } from 'recoil/useUserState';
import { setCookie } from 'util/cookie';

/**
 *
 * @returns 쿠키를 생성하는 훅
 */
export default function useSsoRouting() {
  const userInfo = useRecoilValue(useUserState);

  /**
   * 쿠키를 생성하는 함수
   */
  const ssoRouting = (url = '') => {
    const option = {
      path: '/',
      maxAge: 2 * 60 * 60,
      domain: cookieDomainDdu,
    };

    setCookie('bG9naW5JZA', userInfo.loginId, option);
    setCookie('bG1zQ291cnNlVXJs', url, option);
  };

  return {
    ssoRouting,
  };
}
