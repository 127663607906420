import moment from 'moment';
import { atom } from 'recoil';

const startDay = moment(new Date()).clone().startOf('month').format('YYYY-MM-DD');
const endDay = moment(new Date()).clone().endOf('month').format('YYYY-MM-DD');

export const useCalendarMonthData = atom({
  key: 'useCalendarMonthData',
  default: {
    start_at: `${startDay}T00:00:00.000Z`,
    end_at: `${endDay}T23:59:59.999Z`,
  },
});
