import { useMutation, useQueryClient } from '@tanstack/react-query';
import { putDataQuery } from 'api/putDataQuery';
import { getSessionStorage } from 'assets/constant';
import { jwtDecode } from 'jwt-decode';

export default function usePutSupporting(path) {
  const token = getSessionStorage();
  const decoded = jwtDecode(token);

  const queryClient = useQueryClient();

  // 공지사항 수정
  const editMutation = useMutation({
    mutationFn: (data) => putDataQuery(`/help/notification/${path}`, data),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['notice&news', decoded] });
      queryClient.invalidateQueries({ queryKey: ['notice&newsDetail', decoded, path] });
    },
    onError: (error) => {
      console.error('error', error);
    },
  });

  // faq 수정

  const editFaqMutation = useMutation({
    mutationFn: (data) => putDataQuery(`/help/faq/${path}`, data),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['FAQ', decoded] });
      queryClient.invalidateQueries({ queryKey: ['FAQDetail', decoded, path] });
    },
    onError: (error) => {
      console.error('error', error);
    },
  });

  // 자료실 수정
  const editReferenceMutation = useMutation({
    mutationFn: (data) => putDataQuery(`/help/data-room/${path}`, data),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['reference', decoded] });
      queryClient.invalidateQueries({ queryKey: ['referenceDetail', decoded, path] });
    },
    onError: (error) => {
      console.error('error', error);
    },
  });

  return { editMutation, editFaqMutation, editReferenceMutation };
}
