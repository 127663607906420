import { useEffect, useState } from 'react';

import { useQueries } from '@tanstack/react-query';
import { getDataQuery } from 'api';
import { categoryType } from 'assets/constant';
import { SpinnerLoading } from 'components/common';
import EmptyComponent from 'components/common/EmptyComponent';
import { useGetCommonApi } from 'hooks';
import { Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ReactSelect from 'react-select';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { loginUser } from 'recoil/loginUser';

import { useGetAdminSubjectQuery } from 'hooks/useGetAdminSubjectQuery';
import { subjectTeacherState } from 'recoil/subject/subjectTeacherState';
import { viewSubjectTypeControl } from 'util/subject/subjectUtil';

export function SubjectAdmin() {
  const navigate = useNavigate();
  const user = useRecoilValue(loginUser);

  const [departmentId, setDepartmentId] = useState(null);
  const [subjectId, setSubjectId] = useState(null);
  //   const [optionData, setOptionData] = useState([]);
  //   const [lectureOptionData, setLectureOptionData] = useState([]);
  let optionData = [];
  let lectureOptionData = [];
  const setSubjectTeacherState = useSetRecoilState(subjectTeacherState);
  //   /teach/home/sub-accounts
  // 어드민 전체 과목 불러오기 전체 학과 리스트 조회
  const { adminSubjectDepartmentData, adminSubjectDepartmentIsLoading, adminSubjectDepartmentIsError } =
    useGetAdminSubjectQuery();
  //   const { teacherSubjectTitleData } = useGetTeacherQuery();
  const { semesterData, semesterIsLoading, semesterIsError } = useGetCommonApi();

  const handleDepartmentSelectBox = (e) => {
    setDepartmentId(e.value);
    // setLectureOptionData({
    //   value: '',
    //   label: '',
    // });
  };
  const handleSelectBox = (e) => {
    const findData = adminSubjectDepartmentData.find((item) => item.id === e?.value);
    setSubjectId(findData);
  };

  const teacherSubjectApis = useQueries({
    queries: [
      {
        // 교수자 - [학습현황] 주차별 정보 API ( 셀렉트 박스 선택시 그것에 해당되는 모듈의 정보 api )
        queryKey: ['teacherSubjectListItem', user, subjectId?.id],
        queryFn: () =>
          getDataQuery(`/teach/courses/${subjectId?.id}/modules/info?total=${subjectId?.total_student_count}`),
        // enabled: !!subjectId && notStudentRole,
        enabled: false,
      },
      {
        // 교수자 - [학습현황] 과목의 모듈별 완료한 학생 수
        queryKey: ['teacherSubjectCompletedStudent', user, subjectId?.id],
        queryFn: () => getDataQuery(`/teach/courses/${subjectId?.course_id}/modules/completed-count`),
        // enabled: !!subjectId,
        enabled: false,
      },
      {
        // 교수자 - [학습현황] 과목을 수강중인 학생들의 아이디 목록
        queryKey: ['teacherSubjectStudentList', user, subjectId?.id],
        queryFn: () => getDataQuery(`/teach/courses/${subjectId?.course_id}/students`),
        // enabled: !!subjectId,
        enabled: false,
      },
      {
        // 관리자 - 해당 학과의 과목 리스트
        queryKey: ['adminSubjectLectureList', user, departmentId],
        queryFn: () => getDataQuery(`/teach/home/account/${departmentId}/courses`),
        enabled: !!departmentId,
      },
    ],
  });
  const [teacherSubjectList, teacherSubjectCompletedStudentList, teacherSubjectStudentList, adminSubjectLecture] =
    teacherSubjectApis?.map((query) => query);
  const [
    teacherSubjectListData,
    teacherSubjectCompletedStudentListData,
    teacherSubjectStudentListData,
    adminSubjectLectureData,
  ] = teacherSubjectApis?.map((query) => query?.data?.data);

  const { isLoading: teacherSubjectListIsLoading, isError: teacherSubjectListIsError } = teacherSubjectList;
  const { isLoading: teacherSubjectCompletedStudentListIsLoading, isError: teacherSubjectCompletedStudentListIsError } =
    teacherSubjectCompletedStudentList;
  const { isLoading: teacherSubjectStudentListIsLoading, isError: teacherSubjectStudentListIsError } =
    teacherSubjectStudentList;
  const { isLoading: adminSubjectLectureIsLoading, isError: adminSubjectLectureIsError } = adminSubjectLecture;

  const handleSetSubjectDetail = (item) => {
    try {
      const subjectDetailData = {
        courseId: subjectId.course_id,
        moduleId: item.module_id,
        assignment: item.content_id,
        studentLmsUserIds: teacherSubjectStudentListData?.student_id_list,
        course_title: subjectId.course_name,
        title: item.title,
      };

      setSubjectTeacherState(subjectDetailData);
      navigate('detail');
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    optionData = adminSubjectDepartmentData?.map((item) => ({
      value: item.id,
      label: item.name,
    }));

    //   setOptionData(
    //     adminSubjectDepartmentData?.map((item) => ({
    //       value: item.id,
    //       label: item.name,
    //     }))
    //   );
  }, [adminSubjectDepartmentData, departmentId]);
  useEffect(() => {
    lectureOptionData = adminSubjectLectureData?.map((item) => ({
      value: item.id,
      label: item.name,
    }));
    // setLectureOptionData(
    //   adminSubjectLectureData?.map((item) => ({
    //     value: item.id,
    //     label: item.name,
    //   }))
    // );
  }, [adminSubjectLectureData]);

  return (
    <>
      <section>
        <div className='row'>
          <div className='col'>
            <div className=''>
              {/* 위쪽 */}
              <div className='card p-20 px-4'>
                {/* 헤더 */}
                <div className='d-flex justify-content-between align-items-center'>
                  <h3 className='d-flex align-items-center white-nowrap fw-bold'>
                    <i className='bi bi-bank2 me-2 color-primary'></i>
                    {/* 학기 정보 */}
                    {semesterData ? semesterData?.data?.name : '기본학기'}
                  </h3>

                  <div className='d-flex align-items-center w-100 ps-3'>
                    <div className='d-flex align-items-center flex-glow-2'>
                      <label htmlFor='subjectSelectTitle' className='white-nowrap me-3 d-none d-sm-inline'>
                        학과
                      </label>

                      {/* <AsyncSelect
                        defaultInputValue='학과 선택'
                        placeholder='학과 선택'
                        loadOptions={adminSubjectDepartmentData?.map((el) => ({ value: el.id, label: el.name }))}
                      /> */}
                      <ReactSelect
                        className='w-100'
                        defaultInputValue='학과 선택'
                        placeholder='학과 선택'
                        onChange={handleDepartmentSelectBox}
                        options={optionData}
                      />
                    </div>

                    <div className='d-flex align-items-center ms-3 flex-glow-3'>
                      <label htmlFor='subjectSelectTitle' className='white-nowrap me-3 d-none d-sm-inline'>
                        과목
                      </label>
                      <ReactSelect
                        className='w-100'
                        defaultInputValue='과목 선택'
                        placeholder='과목 선택'
                        onChange={handleSelectBox}
                        options={lectureOptionData}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {teacherSubjectListIsLoading && <SpinnerLoading />}
              {adminSubjectLectureData && (
                <>
                  {/* 셀렉트 information */}
                  <div className=''>
                    {adminSubjectLectureData && (
                      <section className='card px-3 py-4 d-flex justify-content-between align-items-center flex-row'>
                        <div className='d-flex fw-bold fz-18'>
                          <h3 className='d-flex --Palette-DarkGray-80'>{subjectId?.course_name}</h3>
                        </div>
                        <div className='d-flex fz-14 noto-sans align-items-center'>
                          <p className='ms-3 fz-12 d-flex align-items-center p-2 rounded text-blue-primary CHA_LIGHT'>
                            <span className=''>교과코드:</span>
                            <span className='fw-bold'>{subjectId?.course_id}</span>
                          </p>
                        </div>
                      </section>
                    )}

                    {/* 아래 카드 */}
                    <div>
                      <div className='mb-3 d-flex px-3'>
                        <i className='bi bi-people-fill me-3'></i>
                        <p className='fz-16'>총 학생 수: {subjectId?.total_student_count}</p>
                      </div>

                      {/* 주차 아이템 */}
                      {adminSubjectLectureData?.map((item, index) => (
                        <div key={item.module_id} className='rounded-2 overflow-hidden bg-white mb-5'>
                          {/* header */}
                          <div className='p-20 Blue_primary fw-bold text-white d-flex justify-content-between align-items-center'>
                            <p>
                              <span className='me-1'>{index + 1}</span>주차
                            </p>
                            <p className='fw-bold'>
                              <i className='bi bi-check-circle-fill --cha_yellow me-2'></i>
                              주차 완료 :
                              {/* {teacherSubjectCompletedStudentListIsLoading
                                ? // TODO 로딩 스피너 나중에 추가
                                  '0'
                                : teacherSubjectCompletedStudentListData[index]?.completed_student_count} */}
                            </p>
                          </div>
                          {/* body */}
                          <div className='p-20 pb-2 fz-14'>
                            <Table className=''>
                              <thead className='fw-bold --Palette-DarkGray-80'>
                                <tr className='border-bottom'>
                                  <th className='text-start p-1 py-3' style={{ width: '17.78%', minWidth: '70px' }}>
                                    카테고리
                                  </th>
                                  <th className='text-start p-1 py-3' style={{ width: '71.22%' }}>
                                    제목
                                  </th>

                                  <th className='text-start p-1 py-3' style={{ width: '11.00%' }}></th>
                                </tr>
                              </thead>
                              <tbody className='--Palette-DarkGray-80'>
                                {item && item?.module_item_list?.length !== 0 ? (
                                  item?.module_item_list?.map((el) => (
                                    <tr key={el.item_id} className='border-bottom'>
                                      <td className='text-start p-1 py-3' style={{ width: '17.78%' }}>
                                        {categoryType(el.type)}
                                      </td>
                                      <td className='text-start p-1 py-3' style={{ width: '71.22%' }}>
                                        {el.title}
                                      </td>

                                      <td className='text-end p-1 py-3' style={{ width: '11.00%' }}>
                                        <button
                                          className='badge link-badge border fz-14'
                                          onClick={() => {
                                            handleSetSubjectDetail(el);
                                          }}
                                        >
                                          {viewSubjectTypeControl(el.type) || <span>자세히 보기</span>}
                                        </button>
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td colSpan={3}>
                                      <div className='d-flex justify-content-center'>
                                        <EmptyComponent text='등록된 강의가 없습니다' transform={true} />
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
