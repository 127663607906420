import moment from 'moment';

/**
 * 날짜를 포맷팅하는 함수입니다.
 * @param {Date} date - 포맷팅할 날짜 객체
 * @returns {string} - 포맷팅된 날짜 문자열
 */
export const momentFormatter = (date) => {
  let dateFormat = '';
  try {
    const format = moment(date).format('YYYY-MM-DD');
    dateFormat = format;
  } catch (error) {
    dateFormat = '';
    return dateFormat;
  }

  return dateFormat;
};
