import { Form } from 'react-bootstrap';

export function BoardTypeFaq({ value, onChange }) {
  return (
    <div className='d-flex align-items-center py-2'>
      <label>게시글 유형</label>
      <Form.Select required value={value} onChange={(e) => onChange(e.target.value)}>
        {/* <option value='NOTIFICATION'>공지사항</option> */}
        {/* <option value='NEWS'>뉴스</option> */}
        <option value='FAQ'>FAQ</option>
        {/* <option value='DATAROOM'>자료실</option> */}
        {/* <option value='MANUAL'>메뉴얼</option> */}
      </Form.Select>
    </div>
  );
}
