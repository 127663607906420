import { useRecoilValue } from 'recoil';
import { loginUser } from 'recoil/loginUser';
import { CardHeader } from '../CardHeader';

export function AssignmentQuiz({ assignment, quiz, isLoading }) {
  const user = useRecoilValue(loginUser);
  return (
    <>
      <div className='col-12 col-sm-6'>
        <div className='card card-min info-card sales-card p-20'>
          <div className=''>
            <CardHeader title='과제'>{user?.role === 'STUDENT' ? '미완료' : '미채점'}</CardHeader>

            <div className='d-flex align-items-center'>
              <div className='card-icon rounded-circle d-flex align-items-center justify-content-center CHA_LIGHT'>
                <i className='bi bi-file-earmark-plus-fill' style={{ color: '#F05A22' }}></i>
              </div>
              <div className='me-2 w-100 text-end d-flex justify-content-end align-items-center'>
                <h6>
                  {isLoading && 0}
                  {assignment}
                </h6>
                <h6 className='ms-3'>개</h6>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 퀴즈 */}
      <div className='col-12 col-sm-6'>
        <div className='card card-min info-card sales-card p-20'>
          <div className=''>
            <CardHeader title='퀴즈'>{user?.role === 'STUDENT' ? '미완료' : '미채점'}</CardHeader>

            <div className='d-flex align-items-center'>
              <div className='card-icon rounded-circle d-flex align-items-center justify-content-center'>
                <i className='bi bi-rocket-takeoff-fill'></i>
              </div>
              <div className='me-2 w-100 text-end d-flex justify-content-end align-items-center'>
                <h6>
                  {isLoading && 0}
                  {quiz}
                </h6>{' '}
                <h6 className='ms-3'>개</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
