import { Button, Form } from 'react-bootstrap';
import { BoardContent, BoardFile, BoardFixed, BoardPublic, BoardTitle } from '.';
import { BoardThumbnail } from './BoardThumbnail';
import { BoardType } from './BoardType';

export const WriteForm = () => {
  return (
    <>
      <Form action='' className='write-form'>
        <BoardType />

        <BoardTitle />

        <BoardPublic />

        <BoardFixed />

        <BoardThumbnail />

        <BoardContent />

        <BoardFile />

        {/* 첨부파일 */}

        <div className='d-flex align-items-center justify-content-center border-top pt-3'>
          <Button variant='primary' className='me-2'>
            등록
          </Button>
          <Button variant='secondary'>취소</Button>
        </div>
      </Form>
    </>
  );
};
