export function StreamEmpty() {
  return (
    <div className='d-flex flex-column justify-content-center align-items-center p-5'>
      <svg xmlns='http://www.w3.org/2000/svg' width='150' height='150' viewBox='0 0 150 150' fill='none'>
        <g clipPath='url(#clip0_1472_86590)'>
          <mask id='mask0_1472_86590' maskUnits='userSpaceOnUse' x='-1' y='0' width='151' height='150'>
            <path d='M-0.00390625 1.52588e-05H149.996V150H-0.00390625V1.52588e-05Z' fill='white' />
          </mask>
          <g mask='url(#mask0_1472_86590)'>
            <path
              d='M96.7471 88.5C97.6673 87.0583 98.2028 85.3474 98.2028 83.5102C98.2028 78.3797 94.0436 74.2204 88.9131 74.2204C83.7823 74.2204 79.623 78.3797 79.623 83.5102C79.623 85.3474 80.1586 87.0583 81.0788 88.5'
              stroke='#E0E2E7'
              strokeWidth='4.39453'
              strokeMiterlimit='10'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M33.7246 66.3224V72.6016'
              stroke='#E0E2E7'
              strokeWidth='4.39453'
              strokeMiterlimit='10'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M69.8441 34.9934H37.0788C35.2264 34.9934 33.7246 36.4951 33.7246 38.3476V52.9688'
              stroke='#E0E2E7'
              strokeWidth='4.39453'
              strokeMiterlimit='10'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M105.963 46.3281H91.884C90.9942 46.3281 90.1411 45.9748 89.5121 45.3458L84.3418 40.1755'
              stroke='#E0E2E7'
              strokeWidth='4.39453'
              strokeMiterlimit='10'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M147.8 132.031V49.6906C147.8 47.8385 146.299 46.3367 144.446 46.3367H116.811'
              stroke='#E0E2E7'
              strokeWidth='4.39453'
              strokeMiterlimit='10'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M33.7246 83.4464V88.7266'
              stroke='#E0E2E7'
              strokeWidth='4.39453'
              strokeMiterlimit='10'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M104.07 132.031H147.801L113.589 89.9659C112.952 89.1825 111.996 88.7278 110.987 88.7278H5.55587C2.73077 88.7278 1.17101 92.0067 2.95372 94.1984L32.7164 130.793C33.3533 131.577 34.309 132.031 35.3186 132.031H93.2218'
              stroke='#E0E2E7'
              strokeWidth='4.39453'
              strokeMiterlimit='10'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M45.5176 87.1875V84.2578'
              stroke='#E0E2E7'
              strokeWidth='4.39453'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M45.5176 75.9922V25.0159'
              stroke='#E0E2E7'
              strokeWidth='4.39453'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeDasharray='5.51 8.27'
            />
            <path
              d='M45.5176 20.8828V17.9531H48.4473'
              stroke='#E0E2E7'
              strokeWidth='4.39453'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M57.1191 17.9531H125.046'
              stroke='#E0E2E7'
              strokeWidth='4.39453'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeDasharray='5.78 8.67'
            />
            <path
              d='M129.381 17.9531H132.311V20.8828'
              stroke='#E0E2E7'
              strokeWidth='4.39453'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M132.311 29.9257V100.773'
              stroke='#E0E2E7'
              strokeWidth='4.39453'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeDasharray='6.03 9.04'
            />
            <path
              d='M132.311 105.305V108.234'
              stroke='#E0E2E7'
              strokeWidth='4.39453'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M84.7521 36.3175C84.7521 40.3663 81.4696 43.6484 77.4211 43.6484C73.372 43.6484 70.0898 40.3663 70.0898 36.3175C70.0898 32.2687 73.372 28.9865 77.4211 28.9865C81.4696 28.9865 84.7521 32.2687 84.7521 36.3175Z'
              stroke='#E0E2E7'
              strokeWidth='4.39453'
              strokeMiterlimit='10'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M37.5392 59.6144C37.5392 63.6632 34.257 66.9453 30.2082 66.9453C26.1594 66.9453 22.877 63.6632 22.877 59.6144C22.877 55.5655 26.1594 52.2834 30.2082 52.2834C34.257 52.2834 37.5392 55.5655 37.5392 59.6144Z'
              stroke='#E0E2E7'
              strokeWidth='4.39453'
              strokeMiterlimit='10'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M101.319 57.9485L95.0879 64.1797'
              stroke='#E0E2E7'
              strokeWidth='4.39453'
              strokeMiterlimit='10'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M101.319 64.1797L95.0879 57.9485'
              stroke='#E0E2E7'
              strokeWidth='4.39453'
              strokeMiterlimit='10'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M30.3991 19.2454L24.168 25.4766'
              stroke='#E0E2E7'
              strokeWidth='4.39453'
              strokeMiterlimit='10'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M30.3991 25.4766L24.168 19.2454'
              stroke='#E0E2E7'
              strokeWidth='4.39453'
              strokeMiterlimit='10'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M122.327 122.164H92.7315C91.8166 122.164 90.9543 121.737 90.4 121.009L82.8157 111.043C82.0815 110.079 82.7694 108.691 83.9811 108.691H113.577C114.491 108.691 115.354 109.118 115.908 109.846L123.492 119.812C124.226 120.777 123.539 122.164 122.327 122.164Z'
              stroke='#E0E2E7'
              strokeWidth='4.39453'
              strokeMiterlimit='10'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M74.9981 122.164H61.5547'
              stroke='#E0E2E7'
              strokeWidth='4.39453'
              strokeMiterlimit='10'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M70.0909 114.875H64.3848'
              stroke='#E0E2E7'
              strokeWidth='4.39453'
              strokeMiterlimit='10'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </g>
        </g>
        <defs>
          <clipPath id='clip0_1472_86590'>
            <rect width='150' height='150' fill='white' />
          </clipPath>
        </defs>
      </svg>
      <p className='--Palette-Gray-70 fw-bold font-suit fz-15 letter-6 m-0 mt-3'>오늘 등록된 활동이 없습니다.</p>
    </div>
  );
}
