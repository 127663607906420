import { CardHeader, SpinnerLoading } from 'components/common';
import { SubjectEmpty } from 'components/common/dashboard';
import { StudySubject } from '.';

export function LeadingSubject({ data, isLoading, cardTitle }) {
  return (
    <div className='col-12'>
      <div className='card recent-sales p-20 card-min'>
        <CardHeader title='학습 과목'>{cardTitle}</CardHeader>
        {isLoading ? (
          <SpinnerLoading transform={true} />
        ) : (
          <div className='h-200 scrollY'>
            <table className='table table-borderless max-h-300'>
              <tbody className='text-center'>
                {/* 스켈레톤UI */}
                {/* <tr>
                  <td>{isLoading && <DashboardSkeleton />}</td>
                </tr> */}
                {data && data?.length === 0 ? (
                  <SubjectEmpty />
                ) : (
                  data?.map((item) => {
                    return <StudySubject key={item.course_id} item={item} />;
                  })
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}
