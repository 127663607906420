import { getBlobQuery } from 'api/getFileDownload';

export const noticeAndNewSwitch = (type) => {
  let postType = '';

  switch (type) {
    case 'NOTIFICATION':
      postType = '공지사항';
      break;
    case 'FAQ':
      postType = 'faq';
      break;
    case 'DATAROOM':
      postType = '자료실';
      break;
    case 'MANUAL':
      postType = '메뉴얼';
      break;
    case 'NEWS':
      postType = '뉴스';
      break;

    default:
      postType = '공지사항';
      break;
  }
  return postType;
};

export const pathSupportingSwitch = (type) => {
  let postType = '';

  switch (type) {
    case 'NOTIFICATION':
    case 'NEWS':
      postType = '/notice';
      break;
    case 'FAQ':
      postType = '/faq';
      break;
    case 'DATAROOM':
      postType = '/reference';
      break;
    case 'MANUAL':
      postType = '/manual';
      break;

    default:
      postType = '/notice';
      break;
  }
  return postType;
};

export const sortNoticeAndNewsData = (arr = []) => {
  const newArr = [...arr];
  newArr.sort((a, b) => {
    if (a.topFixFlag === 'Y' && b.topFixFlag !== 'Y') {
      return -1; // a를 b보다 앞으로 이동
    } else if (a.topFixFlag !== 'Y' && b.topFixFlag === 'Y') {
      return 1; // b를 a보다 앞으로 이동
    } else {
      return 0; // 유지
    }
  });
  return newArr;
};

export const handleDownloadFile = async (item) => {
  try {
    const { data: downloadData } = await getBlobQuery(`/attach/${item.fileId}`);
    const url = window.URL.createObjectURL(new Blob([downloadData]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', item.originalName);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  } catch (error) {
    console.error('error', error);
  }
};
