import { Form } from 'react-bootstrap';

export function BoardPopupPublic({ value, onChange }) {
  return (
    <>
      {/* 게시글 공개 */}
      <div className='d-flex align-items-center py-2'>
        <label htmlFor='publicRadio'>게시글 공개</label>
        <Form onChange={(e) => onChange(e.target.value)} className='d-flex align-items-center '>
          <Form.Check
            inline
            label='공개'
            value='OPEN'
            checked={value === 'OPEN' ? true : false}
            name='publicRadio'
            type={'radio'}
            id={`publicRadio-${'radio'}-1`}
          />
          <Form.Check
            inline
            label='비공개'
            value='CLOSED'
            checked={value === 'CLOSED' ? true : false}
            name='publicRadio'
            type={'radio'}
            id={`publicRadio-${'radio'}-2`}
          />
          <Form.Check
            inline
            label='팝업 공개'
            value='POPUP'
            checked={value === 'POPUP' ? true : false}
            name='publicRadio'
            type={'radio'}
            id={`publicRadio-${'radio'}-3`}
          />
        </Form>
      </div>
    </>
  );
}
