import { ScheduleCalendar } from 'components/calendar';
import { CardHeader } from 'components/common';
import BreadCrumb from 'components/shared/BreadCrumb';
import { useGetCalendarApi, useGetCommonApi } from 'hooks';
import useGetCommonQuery from 'hooks/useGetCommonQuery';
import { ActivitiesTitleDate } from 'pages/common';
import { useRecoilState } from 'recoil';
import { useCalendarIndex } from 'recoil/caleandar/calendarIndex';
import { ActivityItem } from '.';

export function Activity() {
  const [calendarIndex, setCalendarIndex] = useRecoilState(useCalendarIndex);
  const { commonLectureNoticeData, commonLectureNoticeIsLoading, commonLectureNoticeIsError } = useGetCommonQuery();

  const switchCalendar = () => {
    setCalendarIndex(!calendarIndex);
  };

  const {
    // 학사일정
    calendarAcademicData,
    calendarAcademicIsLoading,
    calendarAcademicIsError,
    // 유저일정
    calendarUserData,
    calendarUserIsLoading,
    calendarUserIsError,
  } = useGetCalendarApi();

  const {
    // 학기정보
    semesterData,
    semesterIsLoading,
    semesterIsError,
  } = useGetCommonApi();

  return (
    <>
      <main id='main' className='main'>
        <BreadCrumb home={'/activity'} sublink={'/activity'} title={'Activities'} subTitle={'활동'} />

        <section>
          <div className='col-lg-12'>
            <div className='row'>
              <div className='col-lg-8'>
                {/* 오늘 */}

                <ActivityItem
                  day={0}
                  data={commonLectureNoticeData?.today_activity_list}
                  isLoading={commonLectureNoticeIsLoading}
                >
                  {commonLectureNoticeIsLoading || <ActivitiesTitleDate data={commonLectureNoticeData?.today_date} />}
                </ActivityItem>

                {/* 내일 */}
                <ActivityItem
                  day={1}
                  data={commonLectureNoticeData?.tomorrow_activity_list}
                  isLoading={commonLectureNoticeIsLoading}
                >
                  {commonLectureNoticeIsLoading || (
                    <ActivitiesTitleDate data={commonLectureNoticeData?.tomorrow_date} />
                  )}
                </ActivityItem>

                {/* 낼모레 */}
                <ActivityItem
                  day={2}
                  data={commonLectureNoticeData?.dat_activity_list}
                  isLoading={commonLectureNoticeIsLoading}
                >
                  {commonLectureNoticeIsLoading || <ActivitiesTitleDate data={commonLectureNoticeData?.dat_date} />}
                </ActivityItem>
              </div>

              <div className='col-lg-4'>
                <div className='card p-20'>
                  <div className='card-body actCardBody d-flex align-items-center justify-content-between'>
                    <div className='d-flex justify-content-between align-items-center w-100'>
                      <CardHeader title={calendarIndex ? '학사일정' : '나의 일정표'}>
                        {semesterData?.data.name}
                      </CardHeader>
                      <button onClick={switchCalendar} className='mb-20 btn btn-style-none'>
                        <i className='bi bi-repeat fz-22'></i>
                      </button>
                    </div>
                  </div>
                  <div className='p-20'>
                    {/* //TODO 달력들어가는 자리 */}
                    <>
                      <ScheduleCalendar
                        data={
                          calendarIndex
                            ? calendarAcademicData
                              ? calendarAcademicData
                              : []
                            : calendarUserData
                              ? calendarUserData
                              : []
                        }
                      />
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
