import { arrChunk } from 'assets/constant';
import { CardHeader } from 'components/common';
import EmptyComponent from 'components/common/EmptyComponent';
import { PaginationCustom } from 'components/common/pagination';
import BreadCrumb from 'components/shared/BreadCrumb';
import { Sidebar } from 'components/shared/sidebar';
import { useSupporting } from 'hooks';
import { usePatchSupporting } from 'hooks/supporting/usePatchSupporting';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { Form, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { useRecoilState, useRecoilValue } from 'recoil';
import { loginUser } from 'recoil/loginUser';
import { noticeCurrentPage } from 'recoil/supporting/noticeCurrentPage';
import { noticeAndNewSwitch, sortNoticeAndNewsData } from 'util/supporting/noticeUtil';
import { BtnAdminSection } from '../BtnAdminSection';

export function Notice() {
  const userInfo = useRecoilValue(loginUser);
  const [currentPage, setCurrentPage] = useRecoilState(noticeCurrentPage);
  const [checkControl, setCheckControl] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [filterLength, setFilterLength] = useState([]);
  const [title, setTitle] = useState('');
  const [selectItem, setSelectItem] = useState('');
  const [checked, setChecked] = useState(
    Array(10)
      .fill(10)
      .map(() => ({ checkbox: false }))
  );
  const [search, setSearch] = useState('');

  // mutation patch 게시글 지우기
  const { noticeDeleteMutation } = usePatchSupporting();
  const navigate = useNavigate();

  const {
    //  지원센터 공지&뉴스 불러오는 api
    supportNoticeNewsData,
    supportNoticeNewsIsLoading,
    supportNoticeNewsIsError,
  } = useSupporting();

  const handleGoingNoticeDetail = (id) => {
    navigate(`/notice/detail/${id}`);
  };

  const handleClickWrite = () => {
    navigate('/notice/write');
  };
  const handleDeleteNotice = () => {
    //
    const checkItems = filterData[currentPage - 1].filter((_, index) => checked[index].checkbox);
    if (checkItems.length < 1 || !checkItems) return;
    const checkConfirm = window.confirm('정말 삭제하시겠습니까?');
    if (!checkConfirm) return;

    if (checkItems) {
      checkItems.forEach((el) => {
        try {
          noticeDeleteMutation.mutate(el.id);
          resetChecked();
          setCheckControl(false);
          setSearch('');
          setTitle('');
          setCurrentPage(1);
        } catch (error) {
          console.error(error);
        }
      });
    }
  };

  // 체크박스 개별의 bool값을 변경하는 함수
  const handleCheckBoxChange = (e, index) => {
    e.stopPropagation();
    const newChecked = [...checked];
    newChecked[index].checkbox = !newChecked[index][`checkbox`];
    setChecked(newChecked);
  };

  const handleSortIndexBtn = useCallback(
    (type) => {
      let arrIndex = 0;
      const indexTransformArr = supportNoticeNewsData?.map((el) =>
        el.topFixFlag === 'N' ? { ...el, index: ++arrIndex } : el
      );

      const visibleFilterData =
        userInfo?.role === 'ADMIN'
          ? indexTransformArr?.toReversed()
          : indexTransformArr.filter((el) => el.postState === 'OPEN').toReversed();
      const sortData = sortNoticeAndNewsData(visibleFilterData);
      const searchData = sortData?.filter((el) => el.title.includes(title));
      if (type === 'up') {
        setFilterData(arrChunk(searchData?.toReversed(), 10));
      }
      if (type === 'down') {
        setFilterData(arrChunk(searchData?.toSorted(), 10));
      }
      setCurrentPage(1);
      resetChecked();
    },
    [supportNoticeNewsData, title]
  );

  const handleCheckBoxControl = (e) => {
    const allChecked = !checkControl;
    setCheckControl(allChecked);
    setChecked(
      Array(10)
        .fill()
        .map(() => ({ checkbox: allChecked }))
    );
  };

  const handleSearch = useCallback(
    (e) => {
      e.preventDefault();

      let arrIndex = 0;
      const indexTransformArr = supportNoticeNewsData?.map((el) =>
        el.topFixFlag === 'N' ? { ...el, index: ++arrIndex } : el
      );

      const visibleFilterData =
        userInfo?.role === 'ADMIN'
          ? indexTransformArr?.toReversed()
          : indexTransformArr.filter((el) => el.postState === 'OPEN').toReversed();
      if (selectItem) {
        const selectFilterData = visibleFilterData.filter((el) => el.postType === selectItem);
        const sortData = sortNoticeAndNewsData(selectFilterData);
        const searchData = sortData?.filter((el) => el.title.includes(title));
        setFilterLength(searchData);
        setFilterData(arrChunk(searchData, 10));
        setCurrentPage(1);
      }
      if (!selectItem) {
        const sortData = sortNoticeAndNewsData(visibleFilterData);
        const searchData = sortData?.filter((el) => el.title.includes(title));
        setFilterLength(searchData);
        setFilterData(arrChunk(searchData, 10));
        setCurrentPage(1);
      }
    },
    [supportNoticeNewsData, title]
  );

  const handleSelectItem = (e) => {
    let arrIndex = 0;
    const indexTransformArr = supportNoticeNewsData?.map((el) =>
      el.topFixFlag === 'N' ? { ...el, index: ++arrIndex } : el
    );

    let selectType = '';
    selectType = e.target.value;
    const visibleFilterData =
      userInfo?.role === 'ADMIN'
        ? indexTransformArr?.toReversed()
        : indexTransformArr.filter((el) => el.postState === 'OPEN').toReversed();
    if (selectType) {
      const selectFilterData = visibleFilterData.filter((el) => el.postType === selectType);
      const sortData = sortNoticeAndNewsData(selectFilterData);
      const searchData = sortData?.filter((el) => el.title.includes(title));
      setFilterLength(searchData);
      setFilterData(arrChunk(searchData, 10));
      setCurrentPage(1);
      setSelectItem(e.target.value);
    }
    if (!selectType) {
      const sortData = sortNoticeAndNewsData(visibleFilterData);
      const searchData = sortData?.filter((el) => el.title.includes(title));
      setFilterLength(searchData);
      setFilterData(arrChunk(searchData, 10));
      setCurrentPage(1);
      setSelectItem(e.target.value);
    }
  };

  const resetChecked = () => {
    setChecked(
      Array(10)
        .fill()
        .map(() => ({ checkbox: false }))
    );
  };

  useEffect(() => {
    // 에러 반환값 핸들링
    if (!supportNoticeNewsData) return;
    if (supportNoticeNewsData?.errorCode) return;

    let arrIndex = 0;
    const indexTransformArr = supportNoticeNewsData?.map((el) =>
      el.topFixFlag === 'N' ? { ...el, index: ++arrIndex } : el
    );

    const visibleFilterData =
      userInfo?.role === 'ADMIN'
        ? indexTransformArr?.toReversed()
        : indexTransformArr?.filter((el) => el.postState === 'OPEN').toReversed();

    if (selectItem) {
      const selectFilterData = visibleFilterData.filter((el) => el.postType === selectItem);
      const sortData = sortNoticeAndNewsData(selectFilterData);
      const searchData = sortData?.filter((el) => el.title.includes(title));
      setFilterLength(searchData);
      setFilterData(arrChunk(searchData, 10));
    }
    if (!selectItem) {
      const sortData = sortNoticeAndNewsData(visibleFilterData);
      const searchData = sortData?.filter((el) => el.title.includes(title));
      setFilterLength(searchData);
      setFilterData(arrChunk(searchData, 10));
    }
  }, [supportNoticeNewsData, supportNoticeNewsIsLoading]);

  useEffect(() => {
    setChecked(
      Array(10)
        .fill(10)
        .map(() => ({ checkbox: false }))
    );
    setCheckControl(false);
  }, [currentPage, setCurrentPage]);

  useEffect(() => {
    return () => {
      resetChecked();
      setCheckControl(false);
      setSearch('');
      setTitle('');
    };
  }, []);

  return (
    <>
      <Sidebar />
      <main id='main' className='main'>
        <BreadCrumb home={'/notice'} sublink={'/notice'} title={'Help Center'} subTitle={'지원센터'}>
          &nbsp;/ 공지사항
        </BreadCrumb>

        <div className='card p-20'>
          <CardHeader noRightBorder={false} title='공지사항' visibleContent={false}></CardHeader>

          <div className='d-flex justify-content-between align-items-center px-2'>
            <div className='d-flex align-items-center'>
              <label className='me-3 white-nowrap' htmlFor='noticeSelectForm'>
                분류
              </label>
              <Form.Select
                onChange={handleSelectItem}
                value={selectItem}
                id='noticeSelectForm'
                style={{ width: '160px' }}
              >
                <option value=''>전체</option>
                <option value='NOTIFICATION'>공지사항</option>
                <option value='NEWS'>뉴스</option>
              </Form.Select>
            </div>
            <form onSubmit={handleSearch} className='w-100 d-flex justify-content-end align-items-center'>
              <input
                type='text'
                className='border rounded py-2 px-3 flex-1 w-100 ms-4'
                onChange={(e) => setTitle(e.target.value)}
                value={title}
                name='searchNotice'
                id='searchNotice'
                placeholder='제목 검색'
              />

              <BtnAdminSection updateFirstItem={handleClickWrite} updateSecondItem={handleDeleteNotice} />
            </form>
          </div>

          <Table responsive='md' className='mt-3'>
            <thead className='border border-top-0 border-start-0 border-end-0 border-dark'>
              <tr className='fw-bold'>
                {/* <td className={`py-3 ${userInfo.role === 'ADMIN' ? '' : 'd-none'}`}>
                        <input
                          type='checkbox'
                          onChange={(e) => handleCheckBoxChange(e, index)}
                          checked={checked[index].checkbox}
                          name={`checkbox${index}`}
                          id={`checkbox${index}`}
                        />
                      </td> */}
                <th className={`py-3 ${userInfo?.role === 'ADMIN' ? '' : 'd-none'}`}>
                  <input type='checkbox' onChange={handleCheckBoxControl} checked={checkControl} name='' id='' />
                </th>
                <th className='py-3' style={{ width: '7.07%' }}>
                  번호
                </th>
                <th className='py-3 d-none d-sm-table-cell text-start min-w-80' style={{ width: '11.79%' }}>
                  분류
                </th>
                <th className='py-3 text-start' style={{ width: '60.00%' }}>
                  제목
                </th>
                <th className='py-3 text-start d-none d-sm-table-cell' style={{ width: '11.79%' }}>
                  작성자
                </th>
                <th className='py-3 text-start' style={{ width: '18.57%' }}>
                  <div className='d-flex align-items-center justify-content-between'>
                    <span>등록일</span>
                    <div className='d-flex flex-column'>
                      <button
                        onClick={() => handleSortIndexBtn('up')}
                        className='btn btn-style-none btn-style-hover p-0'
                      >
                        <i className='bi bi-caret-up-fill --Palette-Gray-50'></i>
                      </button>
                      <button
                        onClick={() => handleSortIndexBtn('down')}
                        className='btn btn-style-none btn-style-hover p-0'
                      >
                        <i className='bi bi-caret-down-fill --Palette-Gray-50'></i>
                      </button>
                    </div>
                  </div>
                </th>
                <th className='py-3 text-start  d-none d-sm-table-cell min-w-80' style={{ width: '7.07%' }}>
                  조회수
                </th>
              </tr>
            </thead>
            <tbody>
              {filterData?.length === 0 ? (
                <tr>
                  <td colSpan={10}>
                    <div className='text-center py-3'>
                      <EmptyComponent text='등록된 게시물이 없습니다.' transform={true} />
                    </div>
                  </td>
                </tr>
              ) : (
                <>
                  {filterData[currentPage - 1]?.map((item, index) => (
                    <tr
                      className={`cursor-pointer border-bottom ${item.topFixFlag === 'Y' && 'fw-bold'}`}
                      key={item.id}
                    >
                      <td className={`py-3 text-center ${userInfo.role === 'ADMIN' ? '' : 'd-none'}`}>
                        <input
                          type='checkbox'
                          onChange={(e) => handleCheckBoxChange(e, index)}
                          checked={checked[index].checkbox}
                          name={`checkbox${index}`}
                          id={`checkbox${index}`}
                        />
                      </td>
                      <td
                        className='py-3 min-w-50'
                        onClick={() => {
                          handleGoingNoticeDetail(item.id);
                        }}
                      >
                        <div className='d-flex justify-content-center'>
                          {item.topFixFlag === 'Y' ? (
                            <i className='bi bi-pin-angle color-primary'></i>
                          ) : (
                            <span>{item.index}</span>
                          )}
                        </div>
                      </td>
                      <td
                        className='py-3 text-start d-none d-sm-table-cell'
                        onClick={() => {
                          handleGoingNoticeDetail(item.id);
                        }}
                      >
                        {noticeAndNewSwitch(item.postType)}
                      </td>
                      <td
                        className='py-3 pe-5 text-start hiddenTextWrap'
                        onClick={() => {
                          handleGoingNoticeDetail(item.id);
                        }}
                      >
                        {item.title}
                        {item.postState === 'CLOSED' && <i className='ms-2 bi bi-eye-slash'></i>}
                      </td>
                      <td
                        className='py-3 text-start d-none d-sm-table-cell'
                        onClick={() => {
                          handleGoingNoticeDetail(item.id);
                        }}
                      >
                        {item.createdBy}
                      </td>
                      <td
                        className='py-3 text-start min-w-70'
                        onClick={() => {
                          handleGoingNoticeDetail(item.id);
                        }}
                      >
                        {item.createdDate ? moment(item.createdDate).format('YYYY.MM.DD') : '-'}
                      </td>
                      <td
                        className='py-3 text-start  d-none d-sm-table-cell'
                        onClick={() => {
                          handleGoingNoticeDetail(item.id);
                        }}
                      >
                        {item.readCount ? item.readCount : 0}
                      </td>
                    </tr>
                  ))}
                </>
              )}

              {/* <tr className='text-center'>
                <EmptySupporting>등록된 게시물이 없습니다.</EmptySupporting>
              </tr> */}
            </tbody>
          </Table>
          {filterData?.length !== 0 && (
            <div className='d-flex justify-content-center'>
              <PaginationCustom
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                setChecked={setChecked}
                data={filterLength}
                // itemsCountPerPage={checkFilterDataLength + topFixedData?.length}
                itemsCountPerPage={10}
              />
            </div>
          )}
        </div>
      </main>
    </>
  );
}
