import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { useEffect, useLayoutEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import { getLectureQuery } from 'api/getLectureQuery';
import { getSessionStorage, removeSessionStorage } from 'assets/constant';
import { useHeader } from 'hooks';
import useSsoRouting from 'hooks/common/useSsoRouting';
import { useCookies } from 'react-cookie';
import { loginUser } from 'recoil/loginUser';
import { useUserState } from 'recoil/useUserState';
import { userNameSplit } from 'util/admin/adminAccountTable';

function Header() {
  const setUser = useSetRecoilState(useUserState);
  const userInfo = useRecoilValue(useUserState);
  const [cookies, setCookies, removeCookies] = useCookies(['token']);
  const [profileType, setProfileType] = useState(null);
  const resetLogin = useResetRecoilState(loginUser);

  const { ssoRouting } = useSsoRouting();
  const {
    // 메세지
    messageData,
    messageIsLoading,
    messageIsError,
    // 알람
    noticeData,
    noticeIsLoading,
    noticeIsError,
  } = useHeader();
  const access = getSessionStorage();
  const refresh = cookies.refresh;

  const decoded = jwtDecode(access);
  const navigate = useNavigate(resetLogin);

  const [userState, setUserState] = useRecoilState(loginUser);

  useEffect(() => {
    if (!access) return;

    setUserState(decoded);
  }, [navigate]);

  const userLogout = async () => {
    try {
      await axios.patch(
        `${process.env.REACT_APP_SERVER}/logout`,
        {},
        {
          headers: {
            Authorization: `Bearer ${access}`,
            Refresh: refresh,
          },
        }
      );

      removeSessionStorage();
      removeCookies('refreshToken');
      setUser(null);
      navigate('/login', { replace: true });
    } catch (error) {
      console.error(error);
    }
  };

  // 온라인 강의실
  const handleGoingLecture = async () => {
    ssoRouting();
    const { data: getCanvasUrl } = await getLectureQuery('');
    if (getCanvasUrl?.errorCode) return;
    window.location.replace(getCanvasUrl);
  };

  // 알람
  const handleGoingCanvas = async (url) => {
    ssoRouting(url);

    const { data: getCanvasUrl } = await getLectureQuery('');

    if (getCanvasUrl?.errorCode) return;
    window.location.replace(getCanvasUrl);
  };
  // 메세지
  const handleGoingMessage = async () => {
    ssoRouting(process.env.REACT_APP_MESSAGE);

    const { data: getMessageApi } = await getLectureQuery('');
    if (getMessageApi?.errorCode) return;
    window.location.replace(getMessageApi);
  };

  useLayoutEffect(() => {
    if (decoded?.role === 'STUDENT') return setProfileType('student_profile1');
    if (decoded?.role === 'TEACHER') return setProfileType('teacher_profile1');
    if (decoded?.role === 'ADMIN') return setProfileType('admin_profile1');
  }, [decoded]);

  useEffect(() => {
    return () => {
      setProfileType(null);
    };
  }, []);

  return (
    <header id='header' className='header fixed-top d-flex align-items-center'>
      <div className='d-flex align-items-center justify-content-between'>
        <div className='d-flex justify-content-center align-items-center'>
          <Link to='/' className='logo d-flex justify-content-center'>
            <img src='/assets/img/logo.svg' alt='' />
            <img src='/assets/img/name.svg' alt='' className='d-none d-lg-block' />
          </Link>
        </div>
        <i
          className='bi bi-list toggle-sidebar-btn'
          onClick={() => {
            document.body.classList.toggle('toggle-sidebar');
          }}
        ></i>
      </div>

      <nav className='header-nav ms-auto'>
        <ul className='d-flex align-items-center'>
          {/* 강의실 */}

          <li>
            <Link
              // to='https://forms.gle/Zn3e4Upfzr1dHtct5'
              onClick={handleGoingLecture}
              // to={`${process.env.REACT_APP_LECT_URL}`}
              className='py-1 px-2 rounded goLectureLink me-3 white-nowrap'
              variant='outline-primary'
            >
              <span className='d-md-inline d-none'>온라인</span> 강의실
            </Link>
          </li>
          {userInfo?.role === 'STUDENT' && (
            <li className='me-3'>
              <Link
                to='https://forms.gle/Zn3e4Upfzr1dHtct5'
                // to={`${process.env.REACT_APP_LECT_URL}`}
                className='py-1 px-2 rounded goSurvey'
                variant='outline-primary'
              >
                <span className='d-md-inline d-none'>만족도 조사</span>
                <span className='d-md-none d-inline'>
                  <i className='bi bi-file-earmark-bar-graph'></i>
                </span>
              </Link>
            </li>
          )}

          {/* 알람 */}
          <li className='nav-item dropdown'>
            <Link className='nav-link nav-icon' to='#' data-bs-toggle='dropdown'>
              <i className='bi bi-bell'></i>
              <span className='badge badge-number' style={{ background: 'var(--CHA-Blue_primary)' }}>
                {noticeData?.length}
              </span>
            </Link>

            <ul className='dropdown-menu dropdown-menu-end dropdown-menu-arrow notifications'>
              <li className='dropdown-header'>
                새로운 알림 {noticeData && noticeData?.length}건이 있습니다.
                {/* <Link to='#'>
                  <span className='badge rounded-pill bg-primary p-2 ms-2'>View all</span>
                </Link> */}
              </li>
              <li>
                <hr className='dropdown-divider' />
              </li>

              {noticeData &&
                noticeData?.map((item) => {
                  return (
                    <div key={item.notice_id}>
                      <Link onClick={() => handleGoingCanvas(item.url)}>
                        <li className='notification-item pe-5 border-top'>
                          <i className='bi bi-bell-fill'></i>
                          <div>
                            <h4>{item.course_name}</h4>
                            <p>{item.title}</p>
                          </div>
                        </li>
                        {/* <li>
                          <div className='dropdown-divider'></div>
                        </li> */}
                      </Link>
                    </div>
                  );
                })}
              {/* <li className='dropdown-footer'>
                <Link to='#'>더보기</Link>
              </li> */}
            </ul>
          </li>

          {/* 메세지 */}
          <li className='nav-item dropdown'>
            <Link className='nav-link nav-icon' to='#' data-bs-toggle='dropdown'>
              <i className='bi bi-chat-left-text'></i>
              <span className='badge badge-number' style={{ background: 'var(--CHA-Blue_second)' }}>
                {messageData?.length}
              </span>
            </Link>

            <ul className='dropdown-menu dropdown-menu-end dropdown-menu-arrow messages'>
              <li className='dropdown-header'>
                새로운 메시지가 {messageData?.length}건 있습니다.
                <Link onClick={handleGoingMessage}>
                  <span className='badge rounded-pill bg-primary p-2 ms-2'>메시지 보기</span>
                </Link>
              </li>
              <li>
                <hr className='dropdown-divider' />
              </li>

              {messageData &&
                messageData?.map((item) => {
                  return (
                    <div key={item.id}>
                      <Link to='#' onClick={handleGoingMessage}>
                        <li key={uuid()} className='message-item'>
                          <i className='bi bi-chat-left-text-fill px-2 text-black'></i>
                          <div>
                            <h4>{item.content}</h4>
                            <p>{item.title}</p>
                          </div>
                        </li>
                        <li>
                          <div className='dropdown-divider'></div>
                        </li>
                      </Link>
                    </div>
                  );
                })}

              <li className='dropdown-footer'>
                <Link to='#' onClick={handleGoingMessage}>
                  더보기
                </Link>
              </li>
            </ul>
          </li>

          {/* 프로필 */}
          <li className='nav-item dropdown pe-3'>
            <Link className='nav-link nav-profile d-flex align-items-center pe-0' to='#' data-bs-toggle='dropdown'>
              <img
                src={`/assets/img/icon/${profileType}.svg`}
                alt='Profile'
                width={36}
                height={36}
                className='rounded-circle'
              />
              <span className='d-none d-md-block dropdown-toggle ps-2'>{userNameSplit(userState?.name)}</span>
            </Link>

            <ul className='dropdown-menu dropdown-menu-end dropdown-menu-arrow profile'>
              <li className='dropdown-header'>
                <h6>{userNameSplit(userState?.name)}</h6>
                <span>{userState?.subDivision}</span>
              </li>
              <li>
                <hr className='dropdown-divider' />
              </li>

              <li>
                <Link to={'/setting'} className='dropdown-item d-flex align-items-center'>
                  <i className='bi bi-person'></i>
                  <span>내 프로필</span>
                </Link>
              </li>
              <li>
                <hr className='dropdown-divider' />
              </li>

              <li>
                <Link to={'/setting'} className='dropdown-item d-flex align-items-center'>
                  <i className='bi bi-gear'></i>
                  <span>계정 설정</span>
                </Link>
              </li>
              <li>
                <hr className='dropdown-divider' />
              </li>

              <li>
                <Link to={'/faq'} className='dropdown-item d-flex align-items-center'>
                  <i className='bi bi-question-circle'></i>
                  <span>FAQ</span>
                </Link>
              </li>
              <li>
                <hr className='dropdown-divider' />
              </li>

              <li>
                <button onClick={userLogout} className='dropdown-item d-flex align-items-center' to='#'>
                  <i className='bi bi-box-arrow-right'></i>
                  <span>로그아웃</span>
                </button>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
