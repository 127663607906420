import { CardHeader } from 'components/common';
import BreadCrumb from 'components/shared/BreadCrumb';
import { WriteForm } from './WriteForm';

export const Write = () => {
  return (
    <>
      <main id='main' className='main'>
        <BreadCrumb home={'/'} sublink={'/manual'} title={'Help Center'} subTitle={'지원센터'}>
          &nbsp;/ 자료실 / 자료등록/수정
        </BreadCrumb>

        <div className='card p-20'>
          <CardHeader noRightBorder={false} title='공지사항' visibleContent={false}></CardHeader>

          <div className=''>
            <WriteForm />
          </div>
        </div>
      </main>
    </>
  );
};
