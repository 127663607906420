import { getSessionStorage, removeSessionStorage } from 'assets/constant';
import { useLayoutEffect } from 'react';
import { useCookies } from 'react-cookie';
import { Navigate, Outlet, useNavigate } from 'react-router';
import { useRecoilValue } from 'recoil';
import { useUserState } from 'recoil/useUserState';
import Footer from './shared/Footer';
import Header from './shared/Header';
import { Sidebar } from './shared/sidebar';

function PrivateRoute() {
  const [cookies, setCookies, removeCookies] = useCookies(['token']);
  const user = useRecoilValue(useUserState);

  const accessToken = getSessionStorage();
  const refreshToken = cookies.refreshToken;

  const navigate = useNavigate();

  useLayoutEffect(() => {
    // 토큰이 둘중 한개라도 없으면 로그인 페이지로 이동 후, 토큰 삭제
    if (!accessToken || !refreshToken) {
      removeSessionStorage();
      removeCookies('refreshToken');
      navigate('/login');
    }
  }, [navigate, accessToken, refreshToken, removeCookies]);

  return user ? (
    <>
      <Header />
      {/* <NoticePopup /> */}
      <Sidebar />
      <Outlet />
      <Footer />
    </>
  ) : (
    <>
      <Navigate to='/login' replace={true} />
    </>
  );
}

export default PrivateRoute;
