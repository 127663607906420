import { useQueries } from '@tanstack/react-query';
import { getDataQuery } from 'api';
import { getSessionStorage } from 'assets/constant';
import { jwtDecode } from 'jwt-decode';
import { useRecoilValue } from 'recoil';
import { adminQueryKeyState } from 'recoil/adminpopup/adminQueryKeyState';

export const useAdminUserSetting = (data) => {
  const queryKeyState = useRecoilValue(adminQueryKeyState);

  const token = getSessionStorage('accessToken');
  const decoded = jwtDecode(token);

  const checkUsername =
    // role과 username이 모두 있다면?
    queryKeyState.role && queryKeyState.username
      ? `&username=${queryKeyState.username}`
      : queryKeyState.role
        ? // role이 있을때 &
          `&username=${queryKeyState.username}`
        : // role이 없을때 ?
          `username=${queryKeyState.username}`;

  const adminUserSettingApi = useQueries({
    queries: [
      {
        queryKey: ['adminUserProfile', decoded],
        queryFn: () => getDataQuery(`/admin/users/count`),
      },
      {
        queryKey: ['adminUserExcel', decoded],
        queryFn: () => getDataQuery(`/admin/users/list/all`),
      },
      {
        queryKey: ['adminUserProfile', decoded, data],
        queryFn: () => getDataQuery(`/users?id=${data}`),
        enabled: !!data,
      },
      {
        queryKey: ['AllUser', queryKeyState.role, queryKeyState.currentPage, queryKeyState.username],
        queryFn: () =>
          getDataQuery(
            `/admin/users/list?${queryKeyState.role ? `role=${queryKeyState.role}` : ''}${checkUsername}&page=${
              queryKeyState.currentPage - 1
            }`
          ),
      },
    ],
  });

  const [adminAlluserLength, adminUserExcel, adminUserProfile, adminAllUser] = adminUserSettingApi.map(
    (query) => query
  );
  const [adminAlluserLengthData, adminUserExcelData, adminUserProfileData, adminAllUserData] = adminUserSettingApi.map(
    (query) => query?.data?.data
  );

  const { isLoading: adminAlluserLengthIsLoading, isError: adminAlluserLengthIsError } = adminAlluserLength;
  const { isLoading: adminUserExcelIsLoading, isError: adminUserExcelIsError } = adminUserExcel;
  const { isLoading: adminUserProfileIsLoading, isError: adminUserProfileIsError } = adminUserProfile;
  const { isLoading: adminAllUserIsLoading, isError: adminAllUserIsError } = adminAllUser;
  return {
    // 어드민 전체 유저 카운트
    adminAlluserLengthData,
    adminAlluserLengthIsLoading,
    adminAlluserLengthIsError,

    // 어드민 유저 엑셀 다운로드용 유저 정보
    adminUserExcelData,
    adminUserExcelIsLoading,
    adminUserExcelIsError,

    // 어드민이 유저의 정보 조회 api (특정 유저)
    adminUserProfileData,
    adminUserProfileIsLoading,
    adminUserProfileIsError,

    // 검색된 유저 api
    adminAllUserData,
    adminAllUserIsLoading,
    adminAllUserIsError,
  };
};
