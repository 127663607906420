import { Form } from 'react-bootstrap';

export function BoardFixed({ value, onChange }) {
  return (
    <>
      {/* 상단고정여부 */}
      <Form value={value} onChange={(e) => onChange(e.target.value)} className='d-flex py-2'>
        <label htmlFor=''>상단고정여부</label>
        <Form.Check
          inline
          label='고정'
          value='Y'
          checked={value === 'Y' ? true : false}
          name='fixedRadio'
          type={'radio'}
          id={`fixedRadio-${'radio'}-1`}
        />
        <Form.Check
          inline
          label='고정안함'
          value='N'
          checked={value === 'N' ? true : false}
          name='fixedRadio'
          type={'radio'}
          id={`fixedRadio-${'radio'}-2`}
        />
      </Form>
    </>
  );
}
