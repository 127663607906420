import { useCallback } from 'react';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import {
  noticeWriteState,
  setNoticeContentState,
  setNoticeEditFileListState,
  setNoticeFileListState,
  setNoticePostStateState,
  setNoticePostTypeState,
  setNoticeThumbnailListState,
  setNoticeTitleState,
  setNoticeTopFixFlagState,
} from 'recoil/supporting/noticeWriteState';

export function useNoticeState() {
  const noticeState = useRecoilValue(noticeWriteState);
  const resetNoticeState = useResetRecoilState(noticeWriteState);
  const setTitle = useSetRecoilState(setNoticeTitleState);
  const setContent = useSetRecoilState(setNoticeContentState);
  const setPostState = useSetRecoilState(setNoticePostStateState);
  const setPostType = useSetRecoilState(setNoticePostTypeState);
  const setTopFixFlag = useSetRecoilState(setNoticeTopFixFlagState);
  const setThumbnail = useSetRecoilState(setNoticeThumbnailListState);
  const setFileList = useSetRecoilState(setNoticeFileListState);
  const setEditFileList = useSetRecoilState(setNoticeEditFileListState);

  const handleTitleChange = useCallback(
    (title) => {
      setTitle(title);
    },
    [setTitle]
  );
  const handleContentChange = useCallback(
    (content) => {
      setContent(content);
    },
    [setContent]
  );
  const handlePostStateChange = useCallback(
    (state) => {
      setPostState(state);
    },
    [setPostState]
  );
  const handlePostTypeChange = useCallback(
    (type) => {
      setPostType(type);
    },
    [setPostType]
  );
  const handleTopFixFlagChange = useCallback(
    (boolean) => {
      setTopFixFlag(boolean);
    },
    [setTopFixFlag]
  );
  const handleThumbnailChange = useCallback(
    (file) => {
      setThumbnail(file);
    },
    [setThumbnail]
  );
  const handleFileChange = (fileList) => {
    setFileList(fileList);
  };
  const handleEditFileChange = (fileList) => {
    setEditFileList(fileList);
  };

  return {
    noticeState,
    handleTitleChange,
    handleContentChange,
    handlePostStateChange,
    handlePostTypeChange,
    handleTopFixFlagChange,
    handleThumbnailChange,
    handleFileChange,
    resetNoticeState,
    handleEditFileChange,
  };
}
