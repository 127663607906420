import { useGetCommonApi } from 'hooks';
import { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { useRecoilState } from 'recoil';
import { subjectValueState } from 'recoil/subject/subjectValueState';
/**
 * 과목 헤더 컴포넌트입니다.
 *
 * @component
 * @param {Object} data - 과목 데이터
 * @returns {JSX.Element} 과목 헤더 컴포넌트 JSX 요소
 */
export default function SubjectHeader({ data, isLoading }) {
  const [subjectState, setSubjectState] = useRecoilState(subjectValueState);
  const [optionData, setOptionData] = useState([]);
  const {
    // 학기정보
    semesterData,
    semesterIsLoading,
    semesterIsError,
  } = useGetCommonApi();

  const handleSelectBox = (e) => {
    const findCourseId = data?.find((el) => +el.course_id === +e.value);
    setSubjectState({
      ...findCourseId,
    });
  };

  useEffect(() => {
    return setOptionData(
      data?.map((item) => ({
        value: item.course_id,
        label: item.course_name,
      }))
    );
  }, [data]);

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      resolve(
        data?.map((item) => ({
          value: item.course_id,
          label: item.course_name,
        }))
      );
    });
  return (
    <div className='card p-20 px-4'>
      {/* 헤더 */}
      <div className='d-flex justify-content-between align-items-center'>
        <h3 className='d-flex align-items-center white-nowrap fw-bold'>
          <i className='bi bi-bank2 me-2 color-primary'></i>
          {semesterData?.data.name}
        </h3>

        <div className='d-flex align-items-center w-100 ps-5'>
          <label htmlFor='subjectSelectTitle' className='white-nowrap me-3'>
            과목
          </label>
          <AsyncSelect
            className='w-100'
            defaultInputValue='과목 선택'
            placeholder='과목 선택'
            isLoading={isLoading}
            cacheOptions
            defaultOptions={optionData}
            onChange={handleSelectBox}
            loadOptions={promiseOptions}
          />
        </div>
      </div>
    </div>
  );
}
