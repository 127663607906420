import { useRecoilValue } from 'recoil';
import { loginUser } from 'recoil/loginUser';

export function BtnAdminSection({ updateFirstItem, updateSecondItem }) {
  const userInfo = useRecoilValue(loginUser);
  return (
    <>
      {userInfo?.role === 'ADMIN' && (
        <>
          <button
            type='button'
            onClick={updateFirstItem}
            className='btn btn-style-none ms-2'
            style={{ background: 'var(--CHA-Blue_primary)' }}
          >
            <i className='text-white fz-16 bi bi-pencil'></i>
          </button>
          <button
            type='button'
            onClick={updateSecondItem}
            className='btn btn-style-none ms-2'
            style={{ background: 'var(--Palette-Gray-70)' }}
          >
            <i className='text-white fz-16 bi bi-trash'></i>
          </button>
        </>
      )}
    </>
  );
}
