import { useQueries } from '@tanstack/react-query';
import { getDataQuery } from 'api';
import { getSessionStorage } from 'assets/constant';
import { jwtDecode } from 'jwt-decode';

export const useGetAdminSubjectQuery = () => {
  const token = getSessionStorage();
  const decoded = jwtDecode(token);

  const checkAdmin = decoded.role === 'ADMIN';

  const response = useQueries({
    queries: [
      {
        // 어드민 전체 과목 불러오기 전체 학과 리스트 조회
        queryKey: ['adminSubjectLecture', decoded.sub],
        queryFn: () => getDataQuery('/teach/home/sub-accounts'),
        enabled: checkAdmin,
      },
    ],
  });

  const [adminSubjectDepartment] = response?.map((query) => query);
  const [adminSubjectDepartmentData] = response?.map((query) => query?.data?.data);

  // 어드민 전체 과목 불러오기 전체 학과 리스트 조회
  const { isLoading: adminSubjectDepartmentIsLoading, isError: adminSubjectDepartmentIsError } = adminSubjectDepartment;

  return {
    // 어드민 전체 과목 불러오기 전체 학과 리스트 조회
    adminSubjectDepartmentData,
    adminSubjectDepartmentIsLoading,
    adminSubjectDepartmentIsError,
  };
};
