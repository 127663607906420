import Pagination from 'react-js-pagination';

/**
 *
 * @param {number} currentPage useState state
 * @param {function} setCurrentPage useState setState
 * @param {array} data 페이지네이션 배열 대이터
 * @param {number} itemsCountPerPage 한 페이지에 아이템을 보여줄 숫자 (기본값:5)
 * @param {number} importantDataLength 페이지 네이션 되어있는 데이터면 총 갯수를 입력
 * @returns
 */
export function PaginationCustom({ currentPage, setCurrentPage, data, itemsCountPerPage = 5, importantDataLength }) {
  return (
    <div id='pagination' className='d-flex justify-content-center'>
      <Pagination
        activePage={currentPage}
        itemsCountPerPage={itemsCountPerPage}
        totalItemsCount={importantDataLength ? importantDataLength : data?.length}
        pageRangeDisplayed={5}
        firstPageText={<i className='bi bi-chevron-double-left'></i>}
        lastPageText={<i className='bi bi-chevron-double-right'></i>}
        prevPageText={<i className='bi bi-chevron-left'></i>}
        nextPageText={<i className='bi bi-chevron-right'></i>}
        nextLabel={<i className='bi bi-chevron-right'></i>}
        itemClass='text-style-none pagination-item'
        activeClass='pagination-active border-0'
        itemClassFirst={'pagination-firstBtn'}
        itemClassLast={'pagination-lastBtn'}
        onChange={(page) => {
          setCurrentPage(page);
        }}
      />
    </div>
  );
}
