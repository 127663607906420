import { useQueries } from '@tanstack/react-query';
import { getDataQuery } from 'api';
import { useRecoilValue } from 'recoil';
import { loginUser } from 'recoil/loginUser';

export const useGetRequiredApi = () => {
  const user = useRecoilValue(loginUser);

  const checkStudent = user?.role === 'STUDENT';
  const checkTeacher = user?.role === 'TEACHER';
  const checkAdmin = user?.role === 'ADMIN';

  const requiredApis = useQueries({
    queries: [
      {
        //   온라인 학습현황 과목선택 셀렉트박스 쿼리
        queryKey: ['subjectStudent', user],
        queryFn: () => getDataQuery(`/subject/users/me/enrollment/all`),
        enabled: checkStudent,
      },
      {
        // 교수자 / 관리자 - [학습현황] 등록되어 있는 게시중인 과목 리스트 정보 API
        // subject 상위 select-box list
        queryKey: ['teacherSubjectTitle', user],
        queryFn: () => getDataQuery('/teach/me/courses'),
        enabled: checkTeacher,
      },
    ],
  });
  const [studentSelectBoxQuery, teacherSelectBoxQuery] = requiredApis.map((query) => query);
  const [studentSelectBoxQueryData, teacherSelectBoxQueryData] = requiredApis.map((query) => query?.data?.data);

  const { isLoading: studentSelectBoxQueryIsLoading, isError: studentSelectBoxQueryIsError } = studentSelectBoxQuery;
  const { isLoading: teacherSelectBoxQueryIsLoading, isError: teacherSelectBoxQueryIsError } = teacherSelectBoxQuery;

  return {
    //   온라인 학습현황 과목선택 셀렉트박스 쿼리
    studentSelectBoxQueryData,
    studentSelectBoxQueryIsLoading,
    studentSelectBoxQueryIsError,

    // 교수자 / 관리자 - [학습현황] 등록되어 있는 게시중인 과목 리스트 정보 API
    // subject 상위 select-box list
    teacherSelectBoxQueryData,
    teacherSelectBoxQueryIsLoading,
    teacherSelectBoxQueryIsError,
  };
};
