import { getSessionStorage } from 'assets/constant';
import axios from 'axios';
import { getCookie } from 'util/cookie';
const getNewRefreshToken = async () => {
  const accessToken = getSessionStorage();
  const refreshToken = getCookie('refreshToken');

  const result = await axios.post(
    `${process.env.REACT_APP_SERVER}/reissue`,
    { refreshToken, accessToken },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Refresh: refreshToken,
      },
    }
  );

  return result;
};

export { getNewRefreshToken };
