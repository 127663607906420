import { CardHeader, IsDone } from "components/common";
import { CloseButton, Table } from "react-bootstrap";

export const SubjectTeacherPopup = ({ setVisiblePopup }) => {
  const closePopup = () => {
    setVisiblePopup(false);
  };
  return (
    <>
      <div
        onClick={closePopup}
        className="backdrop w-100 h-100 position-fixed top-0 start-0 w-100 h-100"
      ></div>
      <div
        className="bg-white position-fixed position-center p-4 rounded-2 popupContainer"
        style={{ width: "80%" }}
      >
        {/* 헤더 */}
        <div className="d-flex align-items-center justify-content-between">
          <CardHeader title="개인별 학습 현황">2023년도 2학기</CardHeader>
          <CloseButton onClick={closePopup} style={{ fontSize: "24px" }} />
        </div>
        <div className="border border-start-0 border-end-0 p-2">
          <ul>
            <li className="d-flex my-2">
              <h4 className="me-5">과목</h4>
              <span>웹 기획 기초I</span>
            </li>
            <li className="d-flex my-2">
              <h4 className="me-5">주차</h4>
              <span>1</span>
            </li>
          </ul>
        </div>

        <Table className="">
          <thead>
            <tr className="fw-bold border border-2 border-start-0 border-end-0 border-top-0 mb-2">
              <th>학번</th>
              <th>이름</th>
              <th>제목</th>
              <th>학습유형</th>
              <th>학습현황</th>
              <th>완료일</th>
            </tr>
          </thead>
          <tbody>
            <tr className=" mb-2">
              {/* 학번 */}
              <td className="CHA_LIGHT">
                <div className="d-flex justify-content-center align-items-center w-100 h-100">
                  123456789
                </div>
              </td>
              {/* 이름 */}
              <td className="CHA_LIGHT">
                <div className="d-flex justify-content-center align-items-center w-100 h-100">
                  학생 A
                </div>
              </td>
              {/* 제목 */}
              <td>
                <ul>
                  <li>1차시 강의영상 웹기획 기초</li>
                  <li>학습 참고자료</li>
                  <li>학습 참고자료</li>
                </ul>
              </td>
              {/* 학습유형 */}
              <td>
                <ul>
                  <li>동영상</li>
                  <li>파일</li>
                  <li>퀴즈</li>
                </ul>
              </td>
              {/* 학습현황 */}
              <td>
                <ul>
                  <li className="d-flex align-items-center justify-content-around">
                    <IsDone /> <span>25:15:00</span>
                  </li>
                  <li className="d-flex align-items-center justify-content-around">
                    <span>-</span>
                    <span>-</span>
                  </li>
                  <li className="d-flex align-items-center justify-content-around">
                    <IsDone done={false} /> <span>25:15:00</span>
                  </li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>2023.07.11</li>
                </ul>
              </td>
            </tr>
            <tr className=" mb-2">
              {/* 학번 */}
              <td className="CHA_LIGHT">
                <div className="d-flex justify-content-center align-items-center w-100 h-100">
                  123456789
                </div>
              </td>
              {/* 이름 */}
              <td className="CHA_LIGHT">
                <div className="d-flex justify-content-center align-items-center w-100 h-100">
                  학생 A
                </div>
              </td>
              {/* 제목 */}
              <td>
                <ul>
                  <li>1차시 강의영상 웹기획 기초</li>
                  <li>학습 참고자료</li>
                  <li>학습 참고자료</li>
                </ul>
              </td>
              {/* 학습유형 */}
              <td>
                <ul>
                  <li>동영상</li>
                  <li>파일</li>
                  <li>퀴즈</li>
                </ul>
              </td>
              {/* 학습현황 */}
              <td>
                <ul>
                  <li className="d-flex align-items-center justify-content-around">
                    <IsDone /> <span>25:15:00</span>
                  </li>
                  <li className="d-flex align-items-center justify-content-around">
                    <span>-</span>
                    <span>-</span>
                  </li>
                  <li className="d-flex align-items-center justify-content-around">
                    <IsDone /> <span>25:15:00</span>
                  </li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>2023.07.11</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </>
  );
};
