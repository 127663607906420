import { arrChunk } from 'assets/constant';
import { CardHeader } from 'components/common';
import EmptyComponent from 'components/common/EmptyComponent';
import { PaginationCustom } from 'components/common/pagination';
import BreadCrumb from 'components/shared/BreadCrumb';
import { Sidebar } from 'components/shared/sidebar';
import { useSupporting } from 'hooks';
import { usePatchSupporting } from 'hooks/supporting/usePatchSupporting';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { useRecoilState, useRecoilValue } from 'recoil';
import { faqCurrentPage } from 'recoil/supporting/faqCurrentPage';
import { useUserState } from 'recoil/useUserState';
import { sortNoticeAndNewsData } from 'util/supporting/noticeUtil';
import { BtnAdminSection } from '../BtnAdminSection';
import FaqCard from './FaqCard';

export const Faq = () => {
  const userInfo = useRecoilValue(useUserState);
  const [currentPage, setCurrentPage] = useRecoilState(faqCurrentPage);
  const [checkControl, setCheckControl] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [filterLength, setFilterLength] = useState([]);
  const [title, setTitle] = useState('');
  const [checked, setChecked] = useState(
    Array(10)
      .fill(10)
      .map(() => ({ checkbox: false }))
  );
  const [search, setSearch] = useState('');

  const {
    // faq 삭제
    faqDeleteMutation,
  } = usePatchSupporting();
  const navigate = useNavigate();

  const {
    // faq 전체 데이터 불러오기
    faqData,
    faqIsLoading,
    faqIsError,
  } = useSupporting();

  const handleGoingNoticeDetail = (id) => {
    navigate(`/faq/detail/${id}`);
  };

  const handleClickWrite = () => {
    navigate('/faq/write');
  };

  const handleDeleteNotice = () => {
    const checkItems = filterData[currentPage - 1].filter((_, index) => checked[index].checkbox);
    if (checkItems.length < 1 || !checkItems) return;
    const checkConfirm = window.confirm('정말 삭제하시겠습니까?');
    if (!checkConfirm) return;

    if (checkItems) {
      checkItems.forEach((el) => {
        try {
          faqDeleteMutation.mutate(el.id);
          resetChecked();
          setCheckControl(false);
          setSearch('');
          setTitle('');
          setCurrentPage(1);
        } catch (error) {
          console.error(error);
        }
      });
    }
  };

  // 체크박스 개별의 bool값을 변경하는 함수
  const handleCheckBoxChange = (e, index) => {
    e.stopPropagation();
    const newChecked = [...checked];
    newChecked[index].checkbox = !newChecked[index][`checkbox`];
    setChecked(newChecked);
  };

  const handleSearch = useCallback(
    (e) => {
      e.preventDefault();

      let arrIndex = 0;
      const indexTransformArr = faqData?.map((el) => (el.topFixFlag === 'N' ? { ...el, index: ++arrIndex } : el));

      const visibleFilterData =
        userInfo?.role === 'ADMIN'
          ? indexTransformArr?.toReversed()
          : indexTransformArr.filter((el) => el.postState === 'OPEN').toReversed();
      const sortData = sortNoticeAndNewsData(visibleFilterData);
      const searchData = sortData?.filter((el) => el.title.includes(title));
      setFilterLength(searchData);
      setFilterData(arrChunk(searchData, 10));
      setCurrentPage(1);
    },
    [faqData, title]
  );

  const handleSortIndexBtn = useCallback(
    (type) => {
      let arrIndex = 0;
      const indexTransformArr = faqData?.map((el) => (el.topFixFlag === 'N' ? { ...el, index: ++arrIndex } : el));

      const visibleFilterData =
        userInfo?.role === 'ADMIN'
          ? indexTransformArr?.toReversed()
          : indexTransformArr.filter((el) => el.postState === 'OPEN').toReversed();
      const sortData = sortNoticeAndNewsData(visibleFilterData);
      const searchData = sortData?.filter((el) => el.title.includes(title));
      if (type === 'up') {
        setFilterData(arrChunk(searchData?.toReversed(), 10));
      }
      if (type === 'down') {
        setFilterData(arrChunk(searchData?.toSorted(), 10));
      }
      setCurrentPage(1);
      resetChecked();
    },
    [faqData, title]
  );

  const handleCheckBoxControl = (e) => {
    const allChecked = !checkControl;
    setCheckControl(allChecked);
    setChecked(
      Array(10)
        .fill()
        .map(() => ({ checkbox: allChecked }))
    );
  };

  const resetChecked = () => {
    setChecked(
      Array(10)
        .fill()
        .map(() => ({ checkbox: false }))
    );
  };

  useEffect(() => {
    // 에러 반환값 핸들링
    if (!faqData) return;
    if (faqData?.errorCode) return;

    let arrIndex = 0;
    const indexTransformArr = faqData?.map((el) => (el.topFixFlag === 'N' ? { ...el, index: ++arrIndex } : el));

    const visibleFilterData =
      userInfo?.role === 'ADMIN'
        ? indexTransformArr?.toReversed()
        : indexTransformArr.filter((el) => el.postState === 'OPEN').toReversed();
    const sortData = sortNoticeAndNewsData(visibleFilterData);
    const searchData = sortData?.filter((el) => el.title.includes(title));
    setFilterLength(searchData);
    setFilterData(arrChunk(sortData, 10));
  }, [faqData, faqIsLoading]);

  useEffect(() => {
    setChecked(
      Array(10)
        .fill(10)
        .map(() => ({ checkbox: false }))
    );
    setCheckControl(false);
  }, [currentPage, setCurrentPage]);

  useEffect(() => {
    return () => {
      resetChecked();
      setCheckControl(false);
      setSearch('');
      setTitle('');
    };
  }, []);

  return (
    <>
      <Sidebar />
      <main id='main' className='main'>
        <BreadCrumb home={'/faq'} sublink={'/faq'} title={'Help Center'} subTitle={'지원센터'}>
          &nbsp;/ FAQ
        </BreadCrumb>

        <div className='row'>
          <div className='col-12 col-sm-6 col-lg-3'>
            <div className='p-3 card card-min-170 bg-primary'>
              <div className='py-1'>
                <i className='bi bi-envelope-at text-white' style={{ fontSize: '38px' }}></i>
              </div>
              <h4 className='fw-bold px-1 py-4 h1 text-white'>문의하기</h4>
            </div>
          </div>
          <FaqCard title='연락처' icon='bi-telephone'>
            <p className='lh-base'>
              <a className='color-inherit' href='tel:042-866-0733'>
                042-866-0733 (내선 733)
              </a>
            </p>
            <p>월요일-금요일 09:00~18:00</p>
          </FaqCard>
          <FaqCard title='위치' icon='bi-geo-alt'>
            <p className='lh-base'>대덕대학교 정곡관 1층 교무처 원격교육지원센터</p>
          </FaqCard>
          <FaqCard title='이메일' icon='bi-envelope-at'>
            <p className='lh-base'>
              <a className='color-inherit' href='mailto:dbwjd619@cha.ac.kr'>
                choide@ddu.ac.kr
              </a>
            </p>
          </FaqCard>{' '}
        </div>

        <div className='card p-20'>
          <CardHeader noRightBorder={false} title='FAQ' visibleContent={false}></CardHeader>

          <div className='d-flex justify-content-between align-items-center px-2'>
            <form onSubmit={handleSearch} className='w-100 d-flex justify-content-end align-items-center'>
              <input
                type='text'
                className='border rounded py-2 px-3 flex-1 w-100 ms-4'
                onChange={(e) => setTitle(e.target.value)}
                value={title}
                name='searchNotice'
                id='searchNotice'
                placeholder='제목 검색'
              />

              <BtnAdminSection updateFirstItem={handleClickWrite} updateSecondItem={handleDeleteNotice} />
            </form>
          </div>

          <Table responsive='md' className='mt-3'>
            <thead className='border border-top-0 border-start-0 border-end-0 border-dark'>
              <tr className='fw-bold'>
                <th
                  className={`py-3 text-center ${userInfo?.role === 'ADMIN' ? '' : 'd-none'}`}
                  style={{ width: '7.07%' }}
                >
                  <input type='checkbox' onChange={handleCheckBoxControl} checked={checkControl} name='' id='' />
                </th>
                <th className='py-3 ' style={{ width: '7.07%' }}>
                  번호
                </th>
                <th className='py-3  d-none d-sm-table-cell text-start' style={{ width: '11.79%' }}>
                  분류
                </th>
                <th className='py-3 text-start a-md-auto'>제목</th>
                <th className='py-3 text-start d-none d-sm-table-cell' style={{ width: '11.79%' }}>
                  작성자
                </th>
                <th className='py-3 text-start' style={{ width: '18.57%' }}>
                  <div className='d-flex align-items-center justify-content-between'>
                    <span>등록일</span>
                    <div className='d-flex flex-column'>
                      <button
                        onClick={() => handleSortIndexBtn('up')}
                        className='btn btn-style-none btn-style-hover p-0'
                      >
                        <i className='bi bi-caret-up-fill --Palette-Gray-50'></i>
                      </button>
                      <button
                        onClick={() => handleSortIndexBtn('down')}
                        className='btn btn-style-none btn-style-hover p-0'
                      >
                        <i className='bi bi-caret-down-fill --Palette-Gray-50'></i>
                      </button>
                    </div>
                  </div>
                </th>
                <th className='py-3 text-start  d-none d-sm-table-cell min-w-80' style={{ width: '7.07%' }}>
                  조회수
                </th>
              </tr>
            </thead>
            <tbody>
              {filterData?.length === 0 ? (
                <tr>
                  <td colSpan={10}>
                    <div className='text-center py-3'>
                      <EmptyComponent text='등록된 게시물이 없습니다' transform={true} />
                    </div>
                  </td>
                </tr>
              ) : (
                <>
                  {filterData[currentPage - 1]?.map((item, index) => (
                    <tr
                      className={`cursor-pointer border-bottom ${item.topFixFlag === 'Y' && 'fw-bold'}`}
                      key={item.id}
                    >
                      <td className={`py-3  text-center ${userInfo.role === 'ADMIN' ? '' : 'd-none'}`}>
                        <input
                          type='checkbox'
                          onChange={(e) => handleCheckBoxChange(e, index)}
                          checked={checked[index].checkbox}
                          name={`checkbox${index}`}
                          id={`checkbox${index}`}
                        />
                      </td>
                      <td
                        className='py-3 min-w-50'
                        onClick={() => {
                          handleGoingNoticeDetail(item.id);
                        }}
                      >
                        <div className='d-flex justify-content-center'>
                          {item.topFixFlag === 'Y' ? (
                            <i className='bi bi-pin-angle color-primary'></i>
                          ) : (
                            <span>{item.index}</span>
                          )}
                        </div>
                      </td>
                      <td
                        className='py-3 text-start  d-none d-sm-table-cell'
                        onClick={() => {
                          handleGoingNoticeDetail(item.id);
                        }}
                      >
                        FAQ
                      </td>
                      <td
                        className='py-3 pe-5 text-start hiddenTextWrap'
                        onClick={() => {
                          handleGoingNoticeDetail(item.id);
                        }}
                      >
                        {item.title}
                        {item.postState === 'CLOSED' && <i className='ms-2 bi bi-eye-slash'></i>}
                      </td>
                      <td
                        className='py-3 text-start d-none d-sm-table-cell'
                        onClick={() => {
                          handleGoingNoticeDetail(item.id);
                        }}
                      >
                        {item.createdBy}
                      </td>
                      <td
                        className='py-3 text-start min-w-70'
                        onClick={() => {
                          handleGoingNoticeDetail(item.id);
                        }}
                      >
                        {item.createdDate ? moment(item.createdDate).format('YYYY.MM.DD') : '-'}
                      </td>
                      <td
                        className='py-3 text-start  d-none d-sm-table-cell'
                        onClick={() => {
                          handleGoingNoticeDetail(item.id);
                        }}
                      >
                        {item.readCount ? item.readCount : 0}
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </Table>
          {filterData?.length !== 0 && (
            <div className='d-flex justify-content-center'>
              <PaginationCustom
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                setChecked={setChecked}
                data={filterLength}
                // itemsCountPerPage={checkFilterDataLength + topFixedData?.length}
                itemsCountPerPage={10}
              />
            </div>
          )}
        </div>
      </main>
    </>
  );
};
