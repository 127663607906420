/**
 *
 * @param {boolean} completed 제출 완료 불린 값
 * @param {string} completedAt 제출한 시간
 * @param {string} dueAt 마감 기간
 * @returns
 */
export function IsSuccess({ completed = true, completedAt = '2012', dueAt = '2013', importantComplete = false }) {
  let type = '';
  if (!completed) {
    type = 'red';
  } else if (new Date(completedAt) > new Date(dueAt)) {
    type = 'orange';
  } else {
    type = 'green';
  }

  if (importantComplete) {
    type = 'green';
  }

  return (
    <p
      className={`fw-bold noto-sans fz-12 py-1 px-3 rounded-5 text-nowrap lh-22
    ${type === 'red' && 'isSuccess-red'} 
    ${type === 'orange' && 'isSuccess-orange'} 
    ${type === 'green' && 'isSuccess-green'} 
    `}
    >
      {type === 'red' && '미완료'}
      {type === 'orange' && '지각'}
      {type === 'green' && '완료'}
    </p>
  );
}
