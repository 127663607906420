/**
 * 아이콘 타입에 따라 아이콘 정보를 반환합니다.
 * @param {string} icon - 아이콘 타입
 * @returns {object} - 아이콘 정보 객체
 */
export const checkIconType = (icon) => {
  let iconInfo = '';
  switch (icon) {
    case 'danger':
      iconInfo = { icon: 'bi-exclamation-triangle text-danger', color: '#F05A22', background: '#FEEEE9' };
      break;

    case 'shield':
      iconInfo = { icon: 'bi-shield-shaded', color: '#F05A22', background: '#FEEEE9' };
      break;

    default:
      iconInfo = { icon: 'bi-0-circle', color: '#000000', background: '#ffffff' };
      break;
  }
  return iconInfo;
};
