import { useMutation, useQueryClient } from '@tanstack/react-query';
import { patchDataQuery } from 'api/patchQuery';
import { passwordMaxLength, passwordMinLength } from 'assets/constant';

import { InputAffix } from 'components/common';
import { PasswordToast } from 'components/common/toast/PasswordToast';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useRecoilState } from 'recoil';
import { adminUserPopupState } from 'recoil/adminpopup/adminUserSettingPopup';

export function PasswordChange({ userNum }) {
  const [popupState, setPopupState] = useRecoilState(adminUserPopupState);
  const [passwordInput, setPasswordInput] = useState({
    currentPassword: '',
    newPassword: '',
    checkNewPassword: '',
  });
  const [password, setPassword] = useState({
    currentPassword: 'password',
    newPassword: 'password',
    checkNewPassword: 'password',
  });

  const queryClient = useQueryClient();

  const patchBodyData = {
    userNumber: userNum,
    oldPassword: passwordInput.currentPassword,
    newPassword: passwordInput.newPassword,
    checkNewPassword: passwordInput.checkNewPassword,
  };

  const mutation = useMutation({
    mutationFn: () => patchDataQuery('/users/password', patchBodyData),
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries({ queryKey: ['userInfo'] });
      if (data?.errorCode) {
        setPopupState({
          ...popupState,
          toastState: true,
          toastType: 'danger',
          toastText: '현 비밀번호가 맞지 않습니다.',
        });
        return;
      }
      //
      setPopupState({
        ...popupState,
        toastState: true,
        toastType: 'success',
        toastText: '비밀번호 변경이 완료되었습니다.',
      });

      resetState();
    },
    onError: (error) => {
      console.error(error);
    },
  });
  const handleInputType = (key) => {
    if (key === 'currentPassword')
      return setPassword({
        ...password,
        currentPassword: password.currentPassword === 'password' ? 'text' : 'password',
      });
    if (key === 'newPassword')
      return setPassword({
        ...password,
        newPassword: password.newPassword === 'password' ? 'text' : 'password',
      });
    if (key === 'checkNewPassword')
      return setPassword({
        ...password,
        checkNewPassword: password.checkNewPassword === 'password' ? 'text' : 'password',
      });
  };

  const handleChangeInput = (e) => {
    const { id, value } = e.target;
    setPasswordInput({
      ...passwordInput,
      [id]: value,
    });
  };

  const handleSubmitPassword = (e) => {
    e.preventDefault();
    // 비밀번호와 비밀번호 확인이 다르면?
    if (passwordInput.newPassword !== passwordInput.checkNewPassword) {
      setPopupState({
        ...popupState,
        toastState: true,
        toastType: 'danger',
        toastText: '새로운 비밀번호가 동일하지 않습니다.',
      });
      return;
    }

    if (
      passwordInput.currentPassword === passwordInput.newPassword &&
      passwordInput.newPassword === passwordInput.checkNewPassword
    ) {
      setPopupState({
        ...popupState,
        toastState: true,
        toastType: 'danger',
        toastText: '기존 비밀번호와 동일한 비밀번호로 변경하실 수 없습니다.',
      });
      return;
    }
    try {
      mutation.mutate();
    } catch (error) {
      console.error(error);
    }
  };

  const resetState = () => {
    setPasswordInput({
      currentPassword: '',
      newPassword: '',
      checkNewPassword: '',
    });
    setPassword({
      currentPassword: 'password',
      newPassword: 'password',
      checkNewPassword: 'password',
    });
  };

  useEffect(() => {
    return () => {
      resetState();
      setPopupState({
        popupState: false,
        toastState: false,
        toastType: null,
        toastText: '',
      });
    };
  }, []);

  return (
    <>
      <form onSubmit={handleSubmitPassword}>
        <ul>
          <li className=''>
            <div className='py-3 position-relative d-flex'>
              <label className='min-w-110 d-flex align-items-center justify-content-start' htmlFor='currentPassword'>
                현 비밀번호
              </label>
              <div className='position-relative w-50'>
                <input
                  className='p-2 border rounded px-3 w-100'
                  onChange={handleChangeInput}
                  value={passwordInput.currentPassword}
                  type={password.currentPassword}
                  id='currentPassword'
                  required
                  minLength={passwordMinLength}
                  maxLength={passwordMaxLength}
                />
                <InputAffix
                  onClick={() => {
                    handleInputType('currentPassword');
                  }}
                  type={password.currentPassword}
                />
              </div>
            </div>
          </li>
          <li className=''>
            <div className='py-3 position-relative d-flex'>
              <label className='min-w-110 d-flex align-items-center justify-content-start' htmlFor='newPassword'>
                새 비밀번호
              </label>
              <div className='position-relative w-50'>
                <input
                  className='p-2 border rounded px-3 w-100'
                  onChange={handleChangeInput}
                  value={passwordInput.newPassword}
                  type={password.newPassword}
                  id='newPassword'
                  required
                  minLength={passwordMinLength}
                  maxLength={passwordMaxLength}
                />
                <InputAffix
                  onClick={() => {
                    handleInputType('newPassword');
                  }}
                  type={password.newPassword}
                />
              </div>
            </div>
          </li>
          <li className=''>
            <div className='py-3 position-relative d-flex'>
              <label className='min-w-110 d-flex align-items-center justify-content-start' htmlFor='checkNewPassword'>
                비밀번호 확인
              </label>
              <div className='position-relative w-50'>
                <input
                  className='p-2 border rounded px-3 w-100'
                  onChange={handleChangeInput}
                  value={passwordInput.checkNewPassword}
                  type={password.checkNewPassword}
                  id='checkNewPassword'
                  required
                  minLength={passwordMinLength}
                  maxLength={passwordMaxLength}
                />
                <InputAffix
                  onClick={() => {
                    handleInputType('checkNewPassword');
                  }}
                  type={password.checkNewPassword}
                />
              </div>
            </div>
          </li>
          <li className='row py-3'>
            <div className='ml-110 '>
              <Button className='w-md-100' type='submit' variant='primary'>
                수정
              </Button>
            </div>
          </li>
        </ul>
      </form>

      <>
        <PasswordToast type={popupState?.toastState} />
      </>
    </>
  );
}
