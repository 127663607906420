import { AdminUserProfilePopup } from 'components/common/adminuserprofilepopup/AdminUserProfilePopup';
import { PaginationCustom } from 'components/common/pagination';
import BreadCrumb from 'components/shared/BreadCrumb';
import { useAdminPatchApi } from 'hooks/admin/patchapi/useAdminPatchApi';
import { useAdminUserSetting } from 'hooks/admin/useAdminUserSetting';
import { useCallback, useEffect, useState } from 'react';
import { Button, Form, Table } from 'react-bootstrap';
import { useRecoilState } from 'recoil';
import { adminQueryKeyState } from 'recoil/adminpopup/adminQueryKeyState';
import { adminUserPopupState } from 'recoil/adminpopup/adminUserSettingPopup';
import { accountTableIndexCalculator, checkUserRole } from 'util/admin/adminAccountTable';
import { utils, writeFileXLSX } from 'xlsx';

export function LearningBoardAccount() {
  const [popupState, setPopupState] = useRecoilState(adminUserPopupState);
  const [queryKeyState, setQueryKeyState] = useRecoilState(adminQueryKeyState);
  const [selectValue, setSelectValue] = useState(null);
  const [userData, setUserData] = useState('');

  const handleUpdateRole = (e) => {
    setQueryKeyState({ ...queryKeyState, username: search, role: e.target.value, currentPage: 1 });
  };
  const handleUpdateCurrentPage = (currentPage) => {
    setQueryKeyState({ ...queryKeyState, currentPage: currentPage });
  };
  const handleUpdateUsername = () => {
    setQueryKeyState({ ...queryKeyState, username: search, currentPage: 1 });
  };

  const [search, setSearch] = useState('');

  const {
    // 전체 유저수
    adminAlluserLengthData,
    adminAlluserLengthIsLoading,
    adminAlluserLengthIsError,

    // 어드민 유저 엑셀 다운로드용 유저 정보
    adminUserExcelData,
    adminUserExcelIsLoading,
    adminUserExcelIsError,

    // 검색된 유저 api
    adminAllUserData,
    adminAllUserIsLoading,
    adminAllUserIsError,
  } = useAdminUserSetting();

  const { patchUserRoleMutation } = useAdminPatchApi(userData.user_number, selectValue);
  const handleSearchBar = (e) => {
    e.preventDefault();
    handleUpdateUsername(search);
  };

  const handleBtnClick = (item) => {
    setSelectValue(item.role);
    setUserData(item);
    setPopupState({
      ...popupState,
      popupState: true,
    });
  };

  const exportFile = useCallback(() => {
    // userData를 엑셀시트 형식으로 변환
    const ws = utils.json_to_sheet(adminUserExcelData);
    // 빈 엑셀파일을 생성
    const wb = utils.book_new();
    // 엑셀파일에 시트 추가
    utils.book_append_sheet(wb, ws, 'Data');
    // 만들어진 엑셀 파일을 다운로드
    writeFileXLSX(wb, '사용자 현황 목록.xlsx');
  }, [adminUserExcelData]);

  useEffect(() => {
    return () => {
      setPopupState({
        popupState: false,
        toastState: false,
      });
    };
  }, []);

  return (
    <>
      <main id='main' className='main'>
        <BreadCrumb
          home={'/managementboard'}
          sublink={'/managementboard'}
          title={'User Accounts'}
          subTitle={'계정관리'}
        >
          &nbsp; / 학습보드 권한관리
        </BreadCrumb>
        {/* <section className='section dashboard row'>
          <div className='col-6'>
            <div className='col'>
              <div className='card card-min info-card sales-card p-20'>
                <div className=''>
                  <CardHeader title='전체 사용자'>계정 수</CardHeader>

                  <div className='d-flex align-items-center'>
                    <div
                      className='card-icon rounded-circle d-flex align-items-center justify-content-center'
                      style={{ background: '#FFF9EA' }}
                    >
                      <i className='bi bi-people-fill' style={{ color: '#F5CE3E' }}></i>
                    </div>
                    <div className='me-2 w-100 text-end d-flex justify-content-end align-items-center'>
                      <h6>{adminAlluserLengthData ? adminAlluserLengthData : 0}</h6> <h6 className='ms-3'>개</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <section className='mt-5'>
          <h3 className='fw-bold fz-20 mb-3'>사용자 현황</h3>
          <div className='col'>
            {/* 아래 카드 */}
            <div className='card p-20'>
              {/* header */}
              <div className='d-flex justify-content-between align-items-center mb-4'>
                <div className='w-200'>
                  <Form.Select
                    className='ms-2 white-nowrap py-0'
                    id='subjectNotice'
                    name='subjectNotice'
                    onChange={handleUpdateRole}
                    value={queryKeyState.role}
                    style={{ height: '31px' }}
                  >
                    <option value=''>모든 권한</option>
                    <option value='STUDENT'>학생</option>
                    <option value='TEACHER'>교수</option>
                    <option value='ADMIN'>관리자</option>
                  </Form.Select>
                </div>

                <div className='d-flex align-items-center'>
                  <form
                    onSubmit={handleSearchBar}
                    className='search-form d-flex align-items-center me-2'
                    style={{ width: '80%' }}
                  >
                    <input
                      type='text'
                      className='search-input w-100'
                      name='query'
                      placeholder='이름으로 검색'
                      title='Enter search keyword'
                      onChange={(e) => setSearch(e.target.value)}
                      value={search}
                      style={{ height: '31px' }}
                    />
                    <button>
                      <i className='bi bi-search'></i>
                    </button>
                  </form>

                  {/* <button onClick={exportFile} className='badge link-badge border fz-14 me-2 bg-prima'>
                    <i className='bi bi-download'></i>
                  </button> */}
                </div>
              </div>
              {/* body */}
              <div>
                <Table>
                  <thead>
                    <tr className='fw-bold border-bottom'>
                      <th className='py-20 text-center'>번호</th>
                      <th className='py-20 text-start'>학번 /사번 (로그인ID)</th>
                      <th className='py-20 text-start'>이름</th>
                      <th className='py-20 text-start'>학부 /학과</th>
                      <th className='py-20 text-start' colSpan={2}>
                        학습보드 권한
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {adminAllUserData &&
                      adminAllUserData?.content?.map((item, index) => (
                        <tr key={item.user_number} className='border-bottom'>
                          <td className='py-20 text-center' style={{ width: '7.07%' }}>
                            {accountTableIndexCalculator(index, queryKeyState.currentPage)}
                          </td>
                          <td className='py-20 text-start' style={{ width: '18.57%' }}>
                            {item.user_number}
                          </td>
                          <td className='py-20 text-start' style={{ width: '32.22%' }}>
                            {item.username}
                          </td>
                          <td className='py-20 text-start' style={{ width: '18.57%' }}>
                            {item.division}
                            {item.sub_division}
                          </td>
                          <td className='py-20 text-start' style={{ width: '11.79%' }}>
                            {checkUserRole(item.role)}
                          </td>
                          <td className='py-20 text-start' style={{ width: '120px' }}>
                            <div className='d-flex justify-content-center'>
                              <Button
                                onClick={() => {
                                  handleBtnClick(item);
                                }}
                                className='badge link-badge border fz-14'
                              >
                                권한변경
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
                {/* footer */}
                <div>
                  {'data' && (
                    <div className='d-flex align-items-center justify-content-center position-relative'>
                      <p className='float-left position-absolute top-left-50 fz-12'>
                        전체 게시물수 : {adminAllUserData?.totalElements} 전체 페이지 : {queryKeyState.currentPage} /
                        {adminAllUserData?.totalPages}
                        {/* {Math.ceil(noticeList.length / 5)} */}
                      </p>
                      <PaginationCustom
                        currentPage={queryKeyState.currentPage}
                        setCurrentPage={handleUpdateCurrentPage}
                        // data={adminAllUserData?.content}
                        importantDataLength={adminAllUserData?.totalElements}
                        itemsCountPerPage={10}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      {popupState.popupState && (
        // {1 && (

        <AdminUserProfilePopup title='사용자 권한 변경' iconType='shield' successMutation={patchUserRoleMutation}>
          <div className='px-3 w-470 m-auto'>
            <Table className=''>
              <tbody>
                <tr>
                  <th className='py-3 text-start fw-bold'>학번 / 사번</th>
                  <td className='py-3 text-start'>{userData?.user_number}</td>
                </tr>
                <tr>
                  <th className='py-3 text-start fw-bold'>이름</th>
                  <td className='py-3 text-start'>{userData?.username}</td>
                </tr>
                <tr>
                  <th className='py-3 text-start fw-bold'>학부 / 학과</th>
                  <td className='py-3 text-start'>
                    {userData?.division}
                    {userData?.sub_division}
                  </td>
                </tr>
                <tr>
                  <th className='py-3 text-start fw-bold'>학습보드 권한</th>
                  <td className='py-3 text-start'>
                    <div>
                      <Form.Select onChange={(e) => setSelectValue(e.target.value)} value={selectValue}>
                        <option value='STUDENT'>학생</option>
                        <option value='TEACHER'>교수자 / 교직원</option>
                        <option value='ADMIN'>관리자</option>
                      </Form.Select>
                    </div>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </AdminUserProfilePopup>
      )}
    </>
  );
}
